<template>
    <div class="container">
      <button  class="back" @click="goBack">Volver atrás</button>
      <div class="tupedido">
        <p class="tittle">Tu pedido</p>
      </div>
      <hr style="color: #0097B2; margin-top: 12px; margin-bottom: 0px;"/>

      <div v-if="carrito.length > 0">
        <ul class="cart-list">
          <li v-for="(item, index) in carrito" :key="index" class="product" :style="{ color: item.color }">
            <div class="container0">
              <div class="container1">
                <p :style="{ color: item.color }" class="cantidad">{{ item.cantidad }}</p>
                <p class="nombre">{{ item.nombre }}</p>
              </div>
              <div class="container2">
                <ul class="ul" v-if="item.links.length">
                  <li class="li" v-for="(links, i) in item.links" :key="item.id_modelo">{{ links }}</li>
                </ul>
              </div>
            </div>
  
            <div class="container3">
              <button  class="delete" @click="eliminarDelCarrito(index)">
                <i class="fa-solid fa-trash" :style="{ color: item.color }"></i>
              </button>
              <p  class="price">{{ (item.precio).toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}</p>
            </div>
          </li>
        </ul>
      </div>
      <p v-else class="sinproductos">No hay productos añadidos.</p>
      
      <div v-if="carrito.length > 0" class="subtotal">
        <p>Gastos de envío: {{ gastosDeEnvioCalculados === 0 ? 'GRATIS' : (gastosDeEnvioCalculados.toLocaleString("es-ES", { style: "currency", currency: "EUR" })) }}</p>
      </div>


        <div v-if="carrito.length > 0" class="cart-wrapper2">
          <button class="btncart" v-if="user" @click="goCheckout">
            Realizar pedido de {{ calcularPrecioTotal.toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}
          </button>
          <button class="btncart" v-if="!user" @click="goAuth">
            Realizar pedido de {{ calcularPrecioTotal.toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}
          </button>
        </div>
    </div>
    
  </template>
  


  <script>

import { auth } from "../firebase";

  export default {
    name: "Cart",
  
    data() {
      return {
        productos: [],
        carrito: this.$store.getters.getCarrito,
        user: null,
        isMobile: false,
      };
    },
  
    created() {

      auth.onAuthStateChanged((user) => {
        if(user)
        {
          this.user = user;
        }
        else{
          this.user = null;
        }
      });
      },
  
    computed: {
      carrito() {
        return this.$store.state.carrito
      },
      totalCantidad() {
        return this.carrito.reduce((total, item) => total + item.cantidad, 0);
      },

      calcularPrecioTotal() {
          return this.carrito.reduce((total, item) => total + item.precio + this.gastosDeEnvioCalculados, 0);
      },

      gastosDeEnvioCalculados() {
    // Comprueba la cantidad total de productos en el carrito
        if (this.totalCantidad >= 3) {
      // Si hay más de tres productos, los gastos de envío son gratuitos
      return 0;
    } else {
      // Si hay tres productos o menos, los gastos de envío son 5
      return 5;
    }
  },
    },
  
    methods: {

      goCheckout() {
        this.$router.push('/checkout');
      },
  
      goAuth() {
        this.$router.push('/login');
      },
  
      goBack() {
        this.$router.go(-1);
      },

  
      checkMobile() {
        return window.innerWidth < 600;
      },
  
      updateIsMobile() {
        this.isMobile = this.checkMobile();
      },
  
      eliminarDelCarrito(index) {
        this.$store.dispatch('eliminarItemDelCarrito', index);
      },
  
      vaciarElCarrito() {
        this.$store.dispatch('vaciarCarrito');
        localStorage.removeItem('carrito');
      },
    }
  };
  </script>

<style scoped>


.iconPerso{
    color: #ffffff;
    margin-right: 6px;
}
.selected {
    background-color: #0097B2;
    color: white;
    border-radius: 12px;
    padding: 12px;
    
}

.disabled {
  background-color: gray; /* Cambiar el color de fondo a gris */
  /* Otros estilos que quieras aplicar */
}

.tipoSelected{
  cursor: pointer;
    display: flex;
    font-size: 20px;
    margin-bottom: 14px;
    margin-top: 14px;

    color: black;
    text-transform: uppercase;
    border-radius: 12px;
    justify-content: space-around;

}
.pararecoger{
  padding-right: 12px;

}

.pararecoger:disabled {
  background-color: gray;
    color: white;
    padding-left: 6px;

    border-radius: 12px;
}
.subtotal{
  font-size: 20px;
    text-align: center;
  }


.pay{
    color: white;
}

.pay2{
    color: white;
    font-size: 20px;
}


.pay2.disabled {
  color: white;
  background-color: darkgray;
}
.price{
  font-weight: 500;
  font-size: 18px;
}
.cart-wrapper button:disabled {
  /* Estilos para los botones deshabilitados dentro del div */
  background-color: #bdc3c7;
  cursor: not-allowed;
  /* Agrega otros estilos según tus necesidades */
}

.pay {
  font-size: 22px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    color: white;
    height: 65px;
    width: 100%;
    background-color: #0097B2;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cart-wrapper {
  font-size: 24px;
    position: fixed;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    color: white;
    height: 65px;
    width: 95%;
    background-color: #0097B2;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cart-wrapper2 {
    display: flex;
    height: auto;
    border-radius: 12px;
    width: 100%;
    background-color: #0097B2;
    padding: 20px;
    justify-content: center;
    align-items: center;
    color: white;
}
.container {
  padding: 22px;
}
.back{
  margin-bottom: 10px;
    padding: 6px;
    margin-top: 6px;
    font-size: 16px;
    background-color: white;
    color: black;
    border-radius: 12px;
    border: 1px solid #0097b2;
    font-weight: 500;
}

.tupedido {
  display: flex;
  justify-content: space-between;
  font-size: 26px;
}
.tupedidoEnvio{
  font-size: 16px;
}
.tittle{
  margin-bottom: 0px;
}

.cart-list {
  overflow: auto;
 
  padding: 0;
  list-style: none;
}

.btncart{
  font-size: 22px;
    color: white;
    border: none;
    font-weight: 500;
    background-color: #0097B2;
}
.product {
  display: flex;
    margin-bottom: 12px;
    padding: 10px;
    border-radius: 12px;
    background: #f7f7f7;
    justify-content: space-between;
}

.container0{
    display: grid;
}
.container1{
  width: 100%;
    display: flex;
    align-items: baseline;
}
.container2{
    display: flex;
    font-size: 18px;
    padding: 0;

}

.container3{
  font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    
}
.delete{
  margin-right: 4px;
    border: none;
    background-color: #f7f7f7;
}
.sinproductos
{
  font-size: 18px;
    padding: 30px;
    text-align: center;

}

.cantidad {
  margin: 0;
    text-align: center;
    width: 26px;
    height: 25px;
    font-size: 18px;
    margin-right: 6px;
    border-radius: 50%;
    font-weight: 500;
    background-color: white;
}
.nombre{
    margin: 0;
    font-size: 18px;
    font-weight: 500;
}
.ul{
  color: #242e30;
    display: flex;
    flex-flow: column wrap;
    font-weight: unset;
    margin: 0;
    text-align: left;
    list-style: none;
    list-style-image: none;
}
.li{
    margin-top: 4px;
    width: 100%;
    margin-bottom: 0;
}

.fa-trash {
  cursor: pointer;
  margin-right: 6px;
  border: none;
}

@media (max-width: 768px) {
    .gastosEnvioMobile{
            margin-left: 2px;
            font-size: 16px;

    }

    .cart-list {
      margin-bottom: 80px;
    overflow: auto;
    padding: 0;
    margin-top: 20px;
    list-style: none;

}
    .container{
      padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 0;
    padding-top: 0px;
    }
  /* Estilos adicionales para pantallas pequeñas si es necesario */
}

@media (max-width: 576px) {

    .gastosEnvioMobile{
            margin-bottom: 0px;
            margin-left: 2px;
            font-size: 16px;
    }
}
</style>