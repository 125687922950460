<template>
    <div class="legal-overlay" @click.self="cerrarModal">
      <div class="terms-container" @click.stop>
        <h4>Condiciones de uso</h4>
        <p><strong>Julio Maza Candel</strong>, con domicilio en Calle Antonio Machado 23, Navas de San Juan y con DNI 26506566D, interviniendo en nombre propio, y en adelante, <strong>Zatec</strong>.</p>

        <p>Zatec prestará al cliente servicios de acceso y gestión en la plataforma <a href="https://mizatec.es">mizatec.es</a>, permitiendo al cliente actualizar su perfil digital en cualquier momento. Este servicio incluye la posibilidad de realizar cambios ilimitados en los contenidos digitales del cliente.</p>

        <p>Las presentes condiciones exponen los derechos y obligaciones del usuario y de Zatec como proveedor del servicio de acuerdo a los términos establecidos a continuación.</p>

        <p>Zatec supondrá que cualquier usuario que utilice el servicio,<strong> ha leído y aceptado las condiciones de uso</strong>, además Zatec se reserva el derecho de actualizar y modificar las Condiciones de uso sin ningún tipo de aviso previo, estando éstos disponibles siempre en la dirección web <a href="https://mizatec.es/legal">mizatec.es/legal</a>.</p>

        <p>El presente acuerdo tendrá validez a partir de la fecha en la que marque la casilla de aceptación de este acuerdo que aparece en el pie de este documento. Las nuevas características que pudieran añadirse a los servicios estarán sujetas a las Condiciones de uso. En caso de que el usuario continúe haciendo uso del servicio después de cualquier modificación, supondrá su conformidad con dichas modificaciones. Siempre se podrá consultar la versión más reciente de las condiciones de uso en <a href="https://mizatec.es/legal">mizatec.es/legal</a></p>

        <p>El incumplimiento de cualquiera de las Condiciones de uso podrá ser causa de anulación de su cuenta.</p>

        <!-- ///////////////////////////////////////////////// -->
        <h4>Condiciones de la cuenta</h4>

        <p>La utilización de los servicios ofrecidos por Zatec está condicionada a que el individuo haya alcanzado al menos dieciséis (16) años de edad.</p>

        <p>Para el registro efectivo de la cuenta, el individuo deberá proporcionar su nombre legal, una dirección de correo electrónico válida, y completar todos los datos requeridos en el proceso de inscripción.</p>

        <p>El usuario se compromete a suministrar información veraz y precisa. Zatec se reserva el derecho de terminar cualquier cuenta en caso de detectar inexactitudes en la información facilitada o en situaciones de incumplimiento de las normas de uso estipuladas.</p>

        <p>Es responsabilidad exclusiva del usuario garantizar la confidencialidad de los datos de su cuenta. Zatec no asumirá responsabilidad alguna por daños o pérdidas que puedan surgir debido a la negligencia del usuario en la protección de su información de acceso.</p>

        <p>Las partes convienen que la firma electrónica del usuario tendrá la misma validez legal que una firma manuscrita. No obstante, Zatec puede requerir, bajo ciertas circunstancias, una confirmación por escrito por parte del usuario.</p>

    <!-- /////////////////////////////////////////////////////////////////// -->
        <h4>Tipos de contrato</h4>

        <p>La activación de una suscripción a un plan tarifario de Zatec queda formalizada con la introducción por parte del suscriptor de los datos pertinentes de su tarjeta bancaria y la realización del correspondiente primer abono. Se establece de manera irrevocable que no procederán, bajo ninguna circunstancia, reembolsos o devoluciones de cantidad alguna una vez efectuado el pago.</p>

        <p>Corresponde al suscriptor la plena responsabilidad sobre la elección y renovación de sus suscripciones, obligándose a realizar una evaluación exhaustiva de las funcionalidades puestas a disposición por Zatec. Cabe señalar que Zatec no se compromete a la realización de desarrollos a medida para el suscriptor, y toda comunicación relativa a posibles futuras funcionalidades del servicio se proporcionará únicamente a título informativo, sin que ello conlleve obligación contractual alguna por parte de Zatec.</p>

        <p>La suscripción a los servicios de pago se renovará automáticamente al llegar la fecha de caducidad del contrato en curso, salvo que el suscriptor ejerza su derecho de cancelación de la cuenta antes de dicha fecha.</p>

        <p>Zatec se reserva el derecho de alterar las tarifas establecidas para sus servicios de manera unilateral y en cualquier momento, sin que dicha modificación dé lugar a compensación de ninguna índole en favor de los suscriptores.</p>


        <!-- //////////////////////////////////////////////////////////// -->
        <h4>Pago y acceso</h4>

        <p>Para la efectuación de pagos bajo los servicios proporcionados por Zatec, es obligatorio que el usuario disponga de una tarjeta bancaria válida. Dicha tarjeta no es necesaria para la creación de cuentas. Zatec efectuará cargos periódicos recurrentes en función del plan de servicios contratado por el usuario.</p>

        <p>Los cargos correspondientes al servicio serán facturados de forma anticipada en cada periodo de contratación y dichos importes son inapelablemente no reembolsables. No se concederán devoluciones por servicios parcialmente utilizados dentro de un periodo anual, ni se efectuarán reembolsos por inactividad del usuario durante la vigencia de la cuenta. Las excepciones a esta política no serán contempladas bajo ninguna circunstancia.</p>

        <p>Por la presente, el usuario consiente que la confirmación de la recepción de la aceptación del presente contrato no será requerida cuando dicho acuerdo se haya formalizado exclusivamente a través de medios electrónicos.</p>

        <p>En el supuesto de que el usuario requiera una actualización de su plan de servicios debido a un incremento en su volumen de uso, el nuevo cargo correspondiente no se aplicará hasta el siguiente ciclo de facturación. En dicho ciclo, se realizará el cargo del nuevo importe en la tarjeta del usuario, a menos que la cuenta sea previamente cancelada.</p>

        <p>Ante situaciones de impago o devolución de cargos, se notificará al usuario dicha circunstancia, resultando en una pérdida inmediata del acceso al servicio. Los datos asociados a la cuenta serán eliminados transcurridos treinta (30) días desde la fecha del incumplimiento de pago.</p>

        <!-- ///////////////////////////////////////////////////////////// -->
        <h4>Disponibilidad del producto en tienda zatec</h4>
            
        <p>Todos los pedidos realizados a través de nuestra tienda están condicionados a la disponibilidad de los productos y a la ausencia de cualquier circunstancia o evento de fuerza mayor que pudiera influir en el suministro de estos o en la prestación de nuestros servicios, conforme a la cláusula nueve de estas Condiciones.</p>
            
        <p>En caso de enfrentarnos a dificultades para el suministro de los productos o en situaciones donde no dispongamos de stock, nos comprometemos a informar de inmediato al Usuario y proceder al reembolso de cualquier cantidad que se haya pagado por los productos no disponibles.</p>

        <h4>Política de precios y pago en tienda zatec</h4>

        <h6>Precios</h6>
        <p>Los precios mostrados en nuestra plataforma, en Euros (€) incluyen impuestos, excepto cuando la legislación exija una especificación diferente, especialmente en relación con el IVA.</p>

        <p>Los costes de envío no están incluidos en los precios finales de los productos y estos serán variables según la cantidad de productos añadidos. La entrega y/o el envío se efectúan a través de Correos.</p>

        <p>No añadiremos costes adicionales al precio de los productos o servicios de forma automática; solo se incluirán aquellos costes que hayas seleccionado de manera voluntaria.</p>

        <p>Los precios pueden cambiar, pero estos cambios no afectarán a los pedidos para los cuales ya hayas recibido una confirmación.</p>

        <h6>Formas de Pago</h6>
        
        <p>Aceptamos pagos mediante tarjeta de crédito o débito.</p>

        <p>Para asegurar la seguridad y confidencialidad de tus datos de pago, utilizamos un sistema de pago seguro SSL, de la empresa Redsys.</p>

        <p>Las transacciones con tarjeta de crédito están sujetas a la autorización de tu banco. Si tu banco rechaza el pago, no podremos proceder con tu pedido y no seremos responsables por retrasos o falta de entrega.</p>

        <p>Al realizar un pedido, se hará una pre-autorización en tu tarjeta para verificar que hay fondos suficientes. El cargo se efectuará cuando el pedido ha sido recibido correctamente por Zatec.</p>

        <p> Al finalizar el pedido correctamente recibirá un correo electrónico con la confirmacion del pedido y la factura generada del pago realizado.</p>

        <!-- //////////////////////////////////////////////////////////////////////////////////////////// -->
        <h4>Modificaciones del servicio y de los planes</h4>

        <p>Zatec se guarda el derecho de cambiar o pausar el servicio en cualquier momento, según lo vea necesario, ya sea por un corto periodo o de forma definitiva. Esto puede ocurrir con o sin previo aviso.</p>

        <p>Asimismo, Zatec puede modificar las tarifas de sus planes de suscripción. En caso de cambios en las tarifas, te daremos un aviso con al menos 15 días de antelación. Estas actualizaciones se anunciarán tanto en nuestro sitio web como a través de comunicaciones escritas.</p>

        <h4>Cancelación y rescisión</h4>

        <p>Es responsabilidad del usuario cancelar su cuenta de manera correcta. La cancelación se puede realizar en cualquier momento desde la opción Datos > Cuenta > Eliminar. Al cancelar tu cuenta, todo el contenido asociado será eliminado después de 30 días.</p>

        <p>Después de la cancelación, ya no se efectuarán más cargos en tu cuenta. Sin embargo, Zatec tiene la facultad de eliminar una cuenta o restringir el acceso al software si se detecta una violación de nuestras condiciones de uso.</p>

        <h4>Condiciones generales</h4>

        <p>La responsabilidad total del uso adecuado y el acceso a Zatec recae sobre el usuario, quien debe actuar conforme a las leyes vigentes, tanto locales como internacionales, y seguir principios de integridad y respeto. Se requiere que el usuario adhiera a estas Condiciones Generales de Uso de manera rigurosa.</p>

        <p>Queda terminantemente prohibido revender, copiar, duplicar o hacer uso indebido de cualquier elemento del servicio sin una autorización directa y por escrito de Zatec. El uso del servicio para difundir contenido nocivo está estrictamente prohibido.</p>

        <p>Zatec no asegura un servicio sin interrupciones, continuo o exento de errores. Zatec, junto con sus afiliados y patrocinadores, declina cualquier responsabilidad por daños, ya sean directos o indirectos, derivados del uso del servicio. La solución para cualquier usuario disconforme es discontinuar el uso del servicio y proceder con la cancelación de su cuenta.</p>

        <p>En caso de que alguna de estas condiciones se considere no aplicable, ello no afectará la validez de las demás condiciones. Para consultas relacionadas con estas Condiciones de Uso, contacta al soporte técnico de Zatec en: <a href="mailto:soporte@zatec.com">soporte@zatec.com</a>.</p>

        <p>Este documento constituye el acuerdo completo entre el usuario y Zatec respecto al uso del servicio, sustituyendo cualquier acuerdo previo. Estas Condiciones y la relación entre el usuario y Zatec estarán sujetas a las leyes de España, acordando ambas partes la jurisdicción de los tribunales de Úbeda para resolver cualquier disputa relacionada.</p>

        <!-- ///////////////////////////////////////////////////////////////////////////////////////// -->
        <h4>Seguridad y privacidad</h4>

        <p>Zatec se compromete a mantener en confidencialidad toda la información, datos y documentación proporcionados por el usuario durante el periodo de validez de este contrato. Además, se asegura de no divulgar dicha información a terceros, con la excepción de su personal, y únicamente en la medida que sea estrictamente necesario para la ejecución efectiva del contrato.</p>

        <p>El compromiso de confidencialidad aquí establecido permanecerá en efecto durante el término de este contrato y se extenderá por un periodo adicional de cinco años tras la finalización o terminación del contrato, independientemente de la causa.</p>

        <p>La información ingresada por los usuarios en Zatec se almacena en servidores de IONOS ubicados en España, asegurando así el cumplimiento de la normativa de protección de datos y ofreciendo los más altos estándares de seguridad y disponibilidad en el mercado.</p>
        <!-- //////////////////////////////////////////////////////////////////////////////////////// -->
        <h4>Política de devoluciones</h4>

        <h6>Suscripciones</h6>

        <p>Si durante los primeros 30 días de uso de Zatec no estás satisfecho con nuestro servicio, contáctanos en <a href="mailto:soporte@zatec.es">soporte@zatec.es</a>. Nos esforzaremos por resolver tus inquietudes y, de no ser posible, procederemos a la devolución del importe de tu suscripción.</p>

        <h6>Renovación de las Suscripciones</h6>
            
        <p>Las suscripciones se renuevan automáticamente para tu comodidad. Si olvidaste cancelar una suscripción que no deseas continuar y se ha efectuado el cobro, tienes 7 días desde la fecha de renovación para solicitar un reembolso escribiéndonos a <a href="mailto:soporte@zatec.es">soporte@zatec.es</a>.</p>

        <h6>Excepciones en la Política de Devoluciones</h6>
            
        <p>No se aceptarán solicitudes de devolución de aquellos usuarios a quienes se les haya suspendido o cancelado el servicio por incumplir nuestros términos y condiciones.</p>

        <h6>Compra de Productos</h6>
            
        <p>Ofrecemos dispositivos Zatec para la venta dentro de nuestra plataforma. Dispones de 14 días naturales desde la recepción de tu pedido para ejercer tu derecho de desistimiento. Para ello, comunícate con nosotros en <a href="mailto:soporte@zatec.es">soporte@zatec.es</a>. En caso de desistimiento, reembolsaremos todos los pagos recibidos, incluidos los gastos de envío (excepto costes adicionales por una modalidad de envío diferente a la ofrecida por Zatec) sin demoras indebidas, a más tardar 14 días naturales tras ser informados de tu decisión.</p>

        <p>El reembolso se realizará utilizando el mismo método de pago usado para la transacción original, sin costes adicionales para ti. Podríamos retener el reembolso hasta haber recibido los productos o hasta que presentes prueba de su devolución, lo que ocurra primero.</p>

        <p>Debes devolver los productos a Julio Maza Candel, Calle Antonio Machado 23, Navas de San Juan, 23240, sin demoras indebidas y, en cualquier caso, no más tarde de 14 días naturales desde que nos informaste de tu decisión de desistir. Serás responsable del coste directo de devolución de los bienes, así como de cualquier disminución de su valor por manipulación distinta a la necesaria para establecer su naturaleza, características y funcionamiento.</p>

        <h4>AVISO LEGAL</h4>
        <p>mizatec.es</p>
        <section>
            <h6>Información general</h6>
            <p>En cumplimiento con el deber de información dispuesto en la Ley 34/2002 de Servicios de la Sociedad de la Información y el Comercio Electrónico (LSSI-CE) de 11 de julio, se facilitan a continuación los siguientes datos de información general de este sitio web:</p>
            <p>La titularidad de este sitio web, mizatec.es, (en adelante, zatec) la ostenta: Julio Maza Candel, con NIF: 26506566D, y cuyos datos de contacto son:</p>
            <ul>
                <li>Dirección: Calle Antonio Machado 23</li>
                <li>Teléfono de contacto: 618601571</li>
                <li>Email de contacto: soporte@zatec.es</li>
            </ul>
        </section>
        <h4>Política de Privacidad</h4>
            
        <h6>Introducción</h6>
            
        <p>La política de privacidad de Zatec se alinea con la legislación internacional y europea (GDPR) sobre protección de datos, informando a los usuarios sobre sus derechos y deberes en relación con la privacidad de sus datos y explicando las razones detrás del almacenamiento y uso de estos datos.</p>

            
        <h6>Datos Recogidos por Zatec</h6>
            
        <p>Zatec recopila información introducida por el usuario en la plataforma y la almacena tanto en servidores propios como en los de nuestros proveedores de almacenamiento. Al ser un servicio en la nube, el almacenamiento de datos es esencial para nuestra operación, lo cual es aceptado por los usuarios.</p>
        <p>Clasificamos los datos en datos de usuario, que incluyen tu nombre, datos personales y cualquier documento que subas, y datos de cuenta, relacionados con tu negocio.</p>
        <p>También recogemos datos de conexión mediante cookies, que puedes desactivar en tu navegador. Estos datos nos ayudan a entender cómo los usuarios navegan y utilizan Zatec.</p>

        <h6>Responsable del Tratamiento de Datos</h6>   
          <p>Nombre: Julio Maza Candel <br>Dirección: Calle Antonio Mchado 23 Navas de San Juan, Jaén, 23240, España<br> Email:<a href="mailto:soporte@zatec.es">soporte@zatec.es</a></p>

        <h6>Finalidad del Tratamiento de Datos</h6>
          <p>En Zatec tratamos la información proporcionada por los usuarios para prestar y comercializar nuestros servicios y productos.</p>

        <h6>Conservación de Datos</h6>
          <p>Mantenemos tus datos personales solo el tiempo necesario para la prestación de servicios y conforme a lo establecido legalmente.</p>

        <h6>Legitimación y Destinatarios</h6>
          <p>La base legal para el tratamiento de tus datos es la ejecución de un contrato o, en su defecto, tu consentimiento. No compartimos tus datos con terceros, salvo por obligación legal.</p>

        <h6>Derechos del Usuario</h6>
          <p>Tienes derecho a acceder, rectificar o suprimir tus datos, así como otros derechos detallados en esta política. Puedes ejercer tus derechos contactando a <a href="mailto:soporte@zatec.es">soporte@zatec.es</a></p>

        <h6>Seguridad y Confidencialidad</h6>
          <p>En Zatec priorizamos la seguridad de tus datos, utilizando proveedores especializados y tecnologías como el cifrado SSL para proteger tu información.</p>

        <h6>Modificaciones a la Política</h6>
          <p>Nuestra política de privacidad puede cambiar, pero te informaremos de cualquier modificación importante. Consulta esta página para la versión más actualizada.</p>
      
        <button @click="cerrarModal" class="cerrar-btn">Cerrar</button>
      </div>
    </div>
</template>
  
  <script>
  export default {
    name: 'Legal',
    methods: {
      cerrarModal() {
        this.$emit('cerrar');
      }
    }
  }
  </script>
  
  <style scoped>
  .legal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Asegúrate de que el overlay esté sobre otros elementos */
  }
  
  .terms-container {
    background-color: #fff;
    padding: 20px;
    width: 90%;
    max-width: 800px; /* Ajusta según tu necesidad */
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow-y: auto; /* Permite el desplazamiento si el contenido es largo */
    max-height: 90vh; /* Ajusta la altura máxima para asegurarte de que siempre haya espacio alrededor del modal */
    z-index: 1001; /* Asegúrate de que el contenido del modal esté sobre el overlay */
  }
  
  .cerrar-btn {
    display: block;
    margin: 20px auto 0;
    padding: 10px 20px;
    border: none;
    font-size: 20px;
    border-radius: 12px;
    cursor: pointer;
  }
  
  h1, h2 {
    color: #333;
  }
  
  p, ol, li {
    color: #666;
  }
  </style>
  