import  {auth} from "../firebase";
import { createStore } from "vuex";
import router from "@/router";
import { getAuth, GoogleAuthProvider, OAuthProvider, signInWithPopup } from "firebase/auth";

const store = createStore({
    
    state() {
        return {
            user: undefined,
            idNegocio: null,
            nombreNegocio: '',
            registrationError: null,
            productosFiltrados: [],
            carrito: localStorage.getItem('carrito')
            ? JSON.parse(localStorage.getItem('carrito')).filter(item => !item.eliminado)
            : [],
        }
    },
    getters: {
        user(state){
          return state.user
        },

        idNegocio(state) {
          return state.idNegocio;
        },

        registrationError(state) {
            return state.registrationError;
          },
          getCarrito: state => {
            return state.carrito;
          }

      },
    mutations: {    
        setidNegocio(state, idNegocio) {
            state.idNegocio = idNegocio;
          },
          setNombreNegocio(state, nombre) {
            state.nombreNegocio = nombre;
        },
        setUser(state, payload){
            state.user = payload;
        },
        setRegistrationError(state, error) {
            state.registrationError = error;
          },
          agregarItem(state, item) {
            const existingItem = state.carrito.find(i => i.id === item.id && JSON.stringify(i.ingredientesSelec) === JSON.stringify(item.ingredientesSelec)); // Buscar el producto en el carrito
            
            if (existingItem) {
              existingItem.cantidad += item.cantidad;
              existingItem.precio += item.precio;
        
            } else {
              state.carrito.push(item); 
            }
            
            localStorage.setItem('carrito', JSON.stringify(state.carrito));
          },
          eliminarItem(state, index) {
            state.carrito[index].eliminado = true;
            localStorage.setItem('carrito', JSON.stringify(state.carrito));
            state.carrito.splice(index, 1); // Eliminar un elemento del carrito por su índice
        
          },
          vaciarCarrito(state) {
            state.carrito = state.carrito.filter(item => item.restauranteId !== state.restauranteId);
          }
    },
    actions: {

      agregarItemAlCarrito({state, commit }, item) {
        commit('agregarItem', item);
        localStorage.setItem('carrito', JSON.stringify(state.carrito));

      },
      eliminarItemDelCarrito({ state, commit }, index) {
        commit('eliminarItem', index);
        localStorage.setItem('carrito', JSON.stringify(state.carrito));
      },
      vaciarCarrito({ commit }) {
        commit('vaciarCarrito');
        localStorage.removeItem('carrito');
      },
      setIdNegocio({ commit }, idNegocio) {
        localStorage.setItem('idNegocio', idNegocio);
        commit('setidNegocio', idNegocio);
      },
      checkAuth({ commit }) {
        auth.onAuthStateChanged(async function(user) {
          if (user) {
              commit("setUser", user);
          } else {
            commit("setUser", null);
          }
        });

        
      },
      async iniciarSesionConGoogle({ commit }) {
        const provider = new GoogleAuthProvider();
           try {
           await signInWithPopup(getAuth(), provider);
            router.push("/negocios");
           } catch (error) {
           console.error("Error al iniciar sesión con Google:", error);
           }
     },

     async iniciarSesionConApple({ commit }) {
      const provider = new OAuthProvider('apple.com');
         try {
         await signInWithPopup(getAuth(), provider);
          router.push("/negocios");
         } catch (error) {
         console.error("Error al iniciar sesión con Apple:", error);
         }
   },
     async doRegister({ commit }, { name, email, password }) {
      try {
        // Creación del usuario
        await auth.createUserWithEmailAndPassword(email, password);
        const user = auth.currentUser;
    
        // Actualización del perfil del usuario
        await user.updateProfile({
          displayName: name,
        });
    
        // Establecer el usuario en el estado
        commit("setUser", user);
    
      } catch (error) {
        if (error.code === "auth/email-already-in-use") {
          commit("setRegistrationError", "La dirección de correo electrónico ya está en uso, si no recuerda la contraseña");
        } else {
          console.error("Ocurrió un error durante el registro:", error);
        }
      }
    },
    

      getCurrentUser()
        {
            return new  Promise((resolve, reject) => {
                const unsubscribe = auth.onAuthStateChanged(
                    user  => {
                        unsubscribe();
                        resolve(user);
                    },
                    () => {
                        reject();
                    }
                );
            });
        },
        async updateProfile({ commit }, {name, email, password}){
          const user = auth.currentUser;
          if(name){
              await user.updateProfile({
                  displayName : name
              });
          }
          if(email){
              await user.updateEmail(email);
          }
         
          if(password){
              await user.updatePassword(password);
          }
          commit("setUser", user);

      },

      async doLogin({ commit }, {email, password }) {
          await auth.signInWithEmailAndPassword(email, password)
          commit("setUser", auth.currentUser );
      },


      async doReset (contex, email)
      {
          await auth.sendPasswordResetEmail(email);
      },

      async doLogout({ commit })
      {
          await auth.signOut();
          commit("setUser", null);
      },
    },
});

export default store;

store.dispatch("checkAuth")
