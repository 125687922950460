<template> 
    <div class="terms-container"> 
        <h2>USO DE COOKIES:</h2>
        <p>Nuestro sitio web utiliza cookies para mejorar la experiencia del usuario y para recopilar datos estadísticos sobre las visitas y comportamiento de los usuarios en el sitio. Al usar nuestro sitio web, aceptas el uso de cookies de acuerdo con esta política.</p>
        
        <h3>¿Qué son las cookies?</h3>
        <p>Las cookies son pequeños archivos que se almacenan en tu dispositivo cuando visitas ciertos sitios web. Estos archivos permiten que el sitio web recuerde información sobre tu visita, como tu idioma preferido y otras configuraciones.</p>
    
        <h3>¿Cómo usamos las cookies?</h3>
        <p>Utilizamos cookies para entender cómo interactúan los usuarios con nuestro sitio web y mejorar su funcionalidad. Esto nos permite ofrecerte una experiencia personalizada y recordar tus preferencias para futuras visitas.</p>
    
        <h3>Cookies de terceros</h3>
        <p>Además de nuestras propias cookies, también podemos usar cookies de terceros para reportar estadísticas de uso del sitio, entregar publicidad en y a través de nuestro sitio web, y así sucesivamente.</p>
        
        <h3>Control de cookies</h3>
        <p>La mayoría de los navegadores te permiten controlar las cookies a través de sus configuraciones. Sin embargo, si limitas la capacidad de los sitios web para configurar cookies, esto puede empeorar tu experiencia general de usuario. Algunas funcionalidades del sitio podrían dejar de funcionar correctamente.</p>
    </div>
  </template>



<script>
  export default {
    name: 'cookies'
  }
  </script>
  


  <style scoped>
  .terms-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  
  h1, h2 {
    color: #333;
    border-bottom: 2px solid #e0e0e0;
    padding-bottom: 10px;
  
  }
  </style>
  
