<template>
  <div class="row">
    <div class="caja1">
      <router-link class="router-link" to="/datos">Datos</router-link>
      <router-link class="router-link" to="/redes">Redes</router-link>
      <router-link class="router-link" to="/pdf">PDF</router-link>
    </div>
    <div class="caja2" v-if="Negocio && Negocio.nombre">
          <div class="caja2-into" v-if="cartaActual">
            <p v-if="!isUploadComplete"  class="f">Cambia tu archivo PDF</p>
            <hr v-if="!isUploadComplete" class="barra"/>

            <div class="centered-content" v-if="!isUploadComplete">
              <label class="textidioma">Idioma del PDF:</label>
              <select class="selected" v-model="selectedLanguage">
                <option value="espanol">Español</option>
                <option value="ingles">Inglés</option>
              </select>
            </div>

            <div class="prueba">
              <label v-if="!isUploadComplete" for="fileInput" class="custom-file-upload">
                <input id="fileInput" type="file" @change="selectFile" accept="application/pdf" />
                Seleccionar archivo
              </label>

              <span class="seleccionado" v-if="selectedFileName && !isUploadComplete">Seleccionado: {{ selectedFileName }}</span>
              <button class="btn" v-if="selectedFileName  && !isUploadComplete" @click="startUpload" :disabled="!selectedFile">Subir PDF</button>

              <div class="je" v-if="uploaded">
                <progress class="progreso" max="100" :value="progress" v-if="!isUploadComplete"></progress>
                <p v-if="isUploadComplete" class="completado"> Completado</p>
                <i v-if="isUploadComplete" class="fa-solid fa-check icon"></i>
              </div>        
            </div>
          </div>
          <!-- ////////////////////////////AQUI////////////////////////// -->
          <div class="caja2-into" v-if="!cartaActual">
            <p v-if="!isUploadComplete"  class="f">Sube tu archivo PDF</p>
            <hr v-if="!isUploadComplete" class="barra"/>

            <div class="centered-content" v-if="!isUploadComplete">
              <label style="margin-right: 6px; font-size: 20px; font-weight: 500;">Idioma del PDF:</label>
              <select style="color: black; background-color: white; border-radius: 12px; padding: 6px; font-weight: 500;" v-model="selectedLanguage">
                <option style="color: black; background-color: white; " value="espanol">Español</option>
                <option style="color: black; background-color: white;" value="ingles">Inglés</option>
              </select>
            </div>

            <div class="prueba">
              <label v-if="!isUploadComplete" for="fileInput" class="custom-file-upload">
                <input id="fileInput" type="file" @change="selectFile" accept="application/pdf" />
                Seleccionar archivo
              </label>

              <span class="seleccionado" v-if="selectedFileName && !isUploadComplete">Seleccionado: {{ selectedFileName }}</span>
              <button class="btn" v-if="selectedFileName  && !isUploadComplete" @click="startUpload" :disabled="!selectedFile">Subir carta</button>

              <div class="je" v-if="uploaded">
                <progress class="progreso" max="100" :value="progress" v-if="!isUploadComplete"></progress>
                <p v-if="isUploadComplete" class="completado"> Completado</p>
                <i v-if="isUploadComplete" class="fa-solid fa-check icon"></i>
              </div>         
            </div>
          </div>
    
    </div>

    <div v-else class="contenedor-centrado">
      <p class="nohaydatos">No hay datos que mostrar</p>
    </div>

    <div  v-if="cartaActual && cartaActual.length > 0 ||  isUploadComplete" @click="irAPDF" class="caja3">
            <p class="f">¿Cómo se ve? </p>
            <hr class="barra"/>
            <p class="cargandovisualizacion" v-if="!mostrarIframe"> Cargando visualización </p>
            <iframe v-if="mostrarIframe" :src="`https://carta.zatec.es/${idNegocio}`" width="100%" height="600" frameborder="0">
            Tu navegador no soporta iframes.
            </iframe>
    </div>

  </div>
</template>

<script>
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { auth } from "../firebase";
import axios from "axios";

export default {

  data() {
    return {
      countdown: null,
      selectedFileName: null,
      selectedFile: null,
      progress: 0,
      uploaded: false,
      fileURL: null,
      userUid: null,
      selectedLanguage: "espanol",
      cartaActual: null,
      nombreNegocio: "",
      mostrarIframe: false, // Inicialmente, no mostrar el iframe
      Negocio: {
                nombre: '',
                direccion: ''
            },

    }
  },

  created() {
    
    this.initializeUser();
    const idNegocio = localStorage.getItem('idNegocio');
    const nombreNegocio =  localStorage.getItem('nombrenegocio');
    if (idNegocio && nombreNegocio ) {
      this.$store.commit('setidNegocio', idNegocio);
      this.$store.commit('setNombreNegocio', nombreNegocio);
      this.nombreNegocio = nombreNegocio
    }
  },
  
  computed: {
    idNegocio() {
      return this.$store.state.idNegocio;
    },
    isUploadComplete() {
    const complete = this.progress === 100;
    if (complete && this.countdown === null) {
      this.mostrarIframe = false
      setTimeout(() => {
        this.mostrarIframe = true;
      }, 3000); // Ajusta el tiempo de retraso según sea necesario
    }
    return complete;
  },
  },

  methods: {
  irAPDF(){
    const link = `https://carta.zatec.es/${this.idNegocio}`;
      window.open(link, '_blank');
  },

    reloadPage() {
      this.obtenerDatos(this.idNegocio)
    },
    initializeUser() {
      auth.onAuthStateChanged(user => {
        if (user) {
          this.userUid = user.uid;
          this.obtenerDatosNegocio( this.idNegocio, this.userUid)
        } else {
          this.$router.push("/login");
        }
      });
    },
    selectFile(event) {
      this.progress = 0;
      const file = event.target.files[0];

      if (file) {
        const fileSizeMB = file.size / 1024 / 1024;
        if (fileSizeMB > 40) {
          alert('El archivo seleccionado supera el límite de 40 MB. Por favor, selecciona un archivo más pequeño.');
        } else {
          this.selectedFile = file;
          this.selectedFileName = file.name;
        }
      } else {
        this.selectedFile = null;
        this.selectedFileName = '';
      }
    },

    startUpload() {
      const storage = getStorage();
      const fileName = this.selectedLanguage === "espanol" ? "en_español.pdf" : "en_ingles.pdf";
      const storageRef = ref(storage, `${this.idNegocio}/${fileName}`);

      this.uploaded = true;

      const uploadTask = uploadBytesResumable(storageRef, this.selectedFile);

      uploadTask.on("state_changed",
        (snapshot) => {
          this.progress = Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100);

        },
        (error) => {
          console.error("Error subiendo el archivo:", error);
        },
        async () => {  // Este es el callback que se ejecuta una vez que la subida está completa
          await this.retrieveFileURL(storageRef);
          this.progress = 100
        }
      );
    },

    async retrieveFileURL(storageRef) {
      try {
        this.fileURL = await getDownloadURL(storageRef);
        console.log("file url es ", this.fileURL)
        if (this.selectedLanguage === "espanol") {
          await this.actualizarLinkCarta(this.fileURL);
        } else if (this.selectedLanguage === "ingles") {
          await this.actualizarLinkCarta2(this.fileURL);
        }
      } catch (err) {
        console.error("Error retrieving file URL:", err);
      }
    },
    async actualizarLinkCarta(fileURL) {
      console.log("el enlace en español ", fileURL);
      try {
        // Cambio a axios.post
        const response = await axios.post('/actualizarCartaES', {
          url_carta_es: fileURL,
          id_negocio: this.idNegocio,
          cliente_uid: this.userUid
        });
        console.log('Respuesta:', response);
        // Procesar la respuesta
      } catch (error) {
        console.error('Error al actualizar el enlace de la carta:', error);
        // Procesar el error
      }
    },


async actualizarLinkCarta2(fileURL) {
      console.log("el enlace en ingles ", fileURL);
      try {
        // Cambio a axios.post
        const response = await axios.post('/actualizarCartaUK', {
          url_carta_en: fileURL,
          id_negocio: this.idNegocio,
          cliente_uid: this.userUid
        });


        console.log('Respuesta:', response);
        // Procesar la respuesta
      } catch (error) {
        console.error('Error al actualizar el enlace de la carta:', error);
        // Procesar el error
      }
    },

    async obtenerDatos(id_negocio) {
        try {
            const response = await axios.get(`/obtenerDatosCarta/${id_negocio}`);
            console.log("Respuesta del servidor:", response);

            // Verificar si la respuesta contiene datos y no es un array vacío
            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                console.log("Ya existe una carta", response.data);
                this.cartaActual = response.data;
                this.mostrarIframe = true
            } else {
                // Manejar el caso de un array vacío o una respuesta sin datos
                console.log("No se encontraron datos para esta carta.");
                this.cartaActual = null; // O [] si prefieres manejarlo como un array vacío en tu UI
            }
        } catch (error) {
            if (error.response && error.response.status === 404) {
                console.log("La carta no existe para este ID de negocio.");
                this.cartaActual = null; // O [] si prefieres manejarlo como un array vacío
            } else {
                console.error("Error al obtener los datos de la carta:", error);
            }
        }
    },
       
        async obtenerDatosNegocio(id_negocio, cliente_uid) {
            try {
                const response = await axios.post("/datoNegocio", { id_negocio, cliente_uid });
                this.Negocio.nombre = response.data[0].nombre
                this.obtenerDatos(id_negocio)
            } catch (error) {
                console.error("Error al obtener los negocios:", error);
            }
        },
  },
}
</script>

<style scoped>
.icon{
  margin-bottom: 20px;
  font-size: 70px;
}

.textidioma{
  margin-right: 6px; 
  font-size: 20px; 
  font-weight: 500;
}
.ahora{
  font-size: 30px;
    color: #0097B2;
    margin: 0;
    text-align: center;
    font-weight: 500;
    margin-bottom: 12px;
    padding-top: 16px;
}
.number{
  font-size: 28px;
  color: var(--success);
}

.caja1{
  display: flex;
    width: 96%;
    height: 62px;
    border-radius: 12px;
    background: #fff;
    justify-content: center;
    margin-top: 12px;
    margin-bottom: 15px;
    align-items: center;
}
.cargandovisualizacion{
  text-align: center;
  font-size: 18px;
}
.caja2{
  width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;

}

.caja3{
  width: 100%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;
}

.contenedor-centrado {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 80vh; /* Hace que el contenedor tenga todo el alto de la ventana */
  width: 100%; /* Asegura que el contenedor se expanda a todo el ancho disponible */
}

/* Estilos para .nohaydatos */
.nohaydatos {
  font-size: 20px;
  text-align: center;
  margin: 0; /* Elimina cualquier margen por defecto que pueda afectar el centrado */
}

.carta-previsualizacion-container {
  margin-top: 20px;
    background: #fff;
    border-radius: 12px;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
}
.btn {
  background-color: var(--success);
    color: #fff;
    padding: 10px 15px;
    font-size: 22px;
    display: inline-block;
    text-align: center;
    border: none;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 400;
    margin-bottom: 20px;
}

.completado{
  font-weight: 500;
}
.contador{
  font-size: 20px;
  color: var(--success);
}
.selected{
  color: black; background-color: white; border-radius: 12px; padding: 6px; font-weight: 500; font-size: 20px;
}

/* Custom File Upload */
.custom-file-upload {
  background-color: #0097b2;
    padding: 10px 15px;
    color: #fff;
    font-size: 22px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    border-radius: 12px;
    font-weight: 400;
}
.ceenter{
color: black;
  font-size: 20px;
  margin-bottom: 20px;
}

/* Je */
.je {
  display: grid;
  text-align: center;
  color: forestgreen;
  font-size: 42px;
  margin-bottom: 8px;
}

/* Progreso */
.progreso {
  font-size: 26px;
}

/* Row */
.row {
  display: flex;
    flex-wrap: wrap;
    padding: 50px;
    justify-content: center;
}

.nombrenegocio{
  color: rgb(86, 87, 97);
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    text-decoration: underline;
}

/* Container Left */
.container-left {
  display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: center;
  margin-top: 20px;
  align-items: baseline;
  margin-bottom: 10px;
}
.router-link {
  outline: none;
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    text-align: center;
}

.router-link.router-link-active {
  border-radius: 12px;
    padding: 6px;
    outline: none;

    color: black;
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-right: 6px;
    box-shadow: 0px 0px 2px 0px #000000;
}

/* Register Container */
.register-container .register-form-container {
  background: #fff;
  border-radius: 12px;
  padding: 16px;
  height: auto;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
}

/* F */
.f {
  
  font-size: 28px;
  color: black;
  margin: 0;
  text-align: center;
  font-weight: 500;
}

/* Variante */

/* Centered Content */
.centered-content {
  display: flex;
  align-items: baseline;
  height: 100%;
  flex-direction: row;
  margin-bottom: 16px;
  font-size: 18px;
  align-content: center;
  justify-content: center;
}

/* Seleccionado */
.seleccionado {
  display: block;
    font-size: 18px;
    color: black;
    text-align: center;
    width: 100%;
    overflow: hidden;
    margin-top: 18px;
    margin-bottom: 18px;
    word-break: break-all;
    font-weight: 500;
}

/* Barra */
.barra {
  color: #0097B2;
  margin-top: 6px;
  margin-bottom: 1.5rem;
  width: 80%;
}

/* Prueba */
.prueba {
  display: grid;
  justify-items: center;
  justify-content: center;
}

/* Estados */

/* Btn:disabled */
button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

/* File input */
input[type="file"] {
  display: none;
}



/* Media Queries */
@media only screen and (max-width: 600px) {
  .row {
    padding: 0px;
    margin: 0;
  }
  .container-left{
    display: flex;
    width: 100%;
    border-radius: 12px;
    justify-content: center;
    /* margin-top: 20px; */
    align-items: baseline;
  }


  .register-container {
    margin-top: 12px;
  }

  .register-container .register-form-container {
    height: auto;
  }
}

@media only screen and (max-width: 100px) {
  .row {
    padding: 0px;
    margin: 0;
  }

  .caja1{
    width: 100%;
  }
  .container-left{
    display: flex;
    width: 100%;
    border-radius: 12px;
    justify-content: center;
    /* margin-top: 20px; */
    align-items: baseline;
  }


  .register-container {
    margin-top: 12px;
  }

  .register-container .register-form-container {
    height: auto;
  }
}

@media (min-width: 1024px) {

  .router-link {
  outline: none;
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 28px;
    font-weight: 500;
    text-align: center;
}

.router-link.router-link-active {
  border-radius: 12px;
    padding: 6px;
    outline: none;

    color: black;
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-weight: 500;
    margin-right: 6px;
    box-shadow: 0px 0px 2px 0px #000000;
}
  .custom-file-upload{
    margin-bottom: 0;
  }
  .selected{
  color: black; 
  background-color: white; 
  border-radius: 12px; 
  padding: 6px; 
  font-weight: 500; 
  font-size: 24px;
}


.textidioma{
  margin-right: 6px; 
  font-size: 24px; 
  font-weight: 500;
}
  .f{
    font-size: 35px;
  }
  .row{
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }
  .caja1{
    width: 60%;
    margin-bottom: 30px;
  }

  .caja2{
    width: 60%;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;
  }
  .caja3{
    width: 60%;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    margin: 0;
    margin-bottom: 30px;

  }
  .container-left{
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: auto;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    margin: 0;

  }
  .icon {
    margin-bottom: 30px; /* Aumentar el margen inferior */
    font-size: 80px; /* Aumentar el tamaño de la fuente */
  }
  .ahora {
    font-size: 35px; /* Aumentar el tamaño de la fuente */
    margin-bottom: 20px; /* Aumentar el margen inferior */
    padding-top: 20px; /* Aumentar el padding superior */
  }
  .number,
  .contador {
    font-size: 32px; /* Aumentar el tamaño de la fuente */
  }
  .btn,
  .custom-file-upload {
    padding: 12px 20px; /* Aumentar el padding */
    font-size: 24px; /* Aumentar el tamaño de la fuente */
  }
  .ceenter {
    font-size: 24px; /* Aumentar el tamaño de la fuente */
    margin-bottom: 25px; /* Aumentar el margen inferior */
  }
  .je {
    font-size: 48px; /* Aumentar el tamaño de la fuente */
  }
  .progreso {
    font-size: 30px; /* Aumentar el tamaño de la fuente */
  }
  .contador {
    font-size: 24px; /* Aumentar el tamaño de la fuente */
  }
  .nombrenegocio,
  .router-link,
  .router-link.router-link-active {
    font-size: 28px; /* Aumentar el tamaño de la fuente */
  }
  .centered-content {
    font-size: 22px; /* Aumentar el tamaño de la fuente */
  }
  .seleccionado {
    font-size: 22px; /* Aumentar el tamaño de la fuente */
  }
}

</style>