<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="row">
        <div class="caja1">
                <router-link class="router-link" to="/datos">Datos</router-link>
                <router-link class="router-link" to="/redes">Redes</router-link>
                <router-link class="router-link" to="/pdf">PDF</router-link>
        </div>
  
        <div class="caja2" v-if="Negocio && Negocio.nombre">
                       <!-- ///////////////////////INSTAGRAM//////////////////////// -->
                    <div class="form-group">
                        <button v-show="redesSocialesOriginales.instagram" class="botonSocial" @click="eliminarInstagram"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>
                        <div class="input-icon-container">
                            <i class="fab fa-instagram iconInstagram"></i>
                            <input type="text" class="form-control" v-model="redesSociales.instagram" placeholder="Nombre de usuario">

                        </div>
                        <button v-if="redesSociales.instagram !== redesSocialesOriginales.instagram && redesSociales.instagram" class="botonSocial" @click="actualizarInstagram">Actualizar</button>
                        <a v-show="redesSociales.instagram && redesSociales.instagram === redesSocialesOriginales.instagram" class="botonSocial" :href="'https://www.instagram.com/' + redesSociales.instagram" target="_blank">Ver </a>
                    </div>


                    <!-- ///////////////////////FACEBOOK//////////////////////// -->

                    <div class="form-group">
                        <button v-show="redesSocialesOriginales.facebook" class="botonSocial" @click="eliminarFacebook"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>
                        <div class="input-icon-container">
                            <i class="fa-brands fa-facebook iconFacebook"></i>
                            <input type="text" class="form-control" v-model="redesSociales.facebook" placeholder="URL de Facebook" />
                        </div>
                        <button v-if="redesSociales.facebook !== redesSocialesOriginales.facebook" class="botonSocial" @click="actualizarFacebook">Actualizar</button>
                        <a v-show="redesSociales.facebook && redesSociales.facebook === redesSocialesOriginales.facebook" class="botonSocial" :href="redesSociales.facebook" target="_blank">Ver</a>
                    </div>

                    <!-- ///////////////////////WHATSAPP//////////////////////// -->

                    <div class="form-group">
                        <button v-show="redesSocialesOriginales.whatsapp" class="botonSocial" @click="eliminarWhatsApp"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>
                        <div class="input-icon-container">
                            <i class="fa-brands fa-whatsapp iconWhatsapp"></i>
                            <input type="number" id="phone" class="form-control" v-model="redesSociales.whatsapp" placeholder="Nº de móvil" />
                        </div>

                        <button v-if="redesSociales.whatsapp !== redesSocialesOriginales.whatsapp && redesSociales.whatsapp" class="botonSocial"  @click="actualizarWhatsApp">Actualizar</button>
                        <a v-show="redesSociales.whatsapp && redesSociales.whatsapp === redesSocialesOriginales.whatsapp" class="botonSocial" :href="'https://api.whatsapp.com/send?phone=34' + redesSociales.whatsapp" target="_blank">Ver</a>
                    </div>

                    <!-- ///////////////////////web//////////////////////// -->

                    <div class="form-group">
                        <button v-show="redesSocialesOriginales.web" class="botonSocial" @click="eliminarWeb"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>

                     <div class="input-icon-container">
                        <i class="fa-solid fa-globe iconweb"></i>
                        <input type="text" class="form-control" v-model="redesSociales.web" placeholder="Página Web" />
                    </div>

                    <button v-if="redesSociales.web !== redesSocialesOriginales.web && redesSociales.web" class="botonSocial"  @click="actualizarWeb">Actualizar</button>
                        <a class="botonSocial" v-show="redesSociales.web && redesSociales.web === redesSocialesOriginales.web"  target="_blank" :href="redesSociales.web" >Ver</a>
                    </div>

                      <!-- ///////////////////////TIKTOK//////////////////////// -->

                      <div class="form-group">
                        <button v-show="redesSocialesOriginales.tiktok"  class="botonSocial" @click="eliminarTikTok"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>

                     <div class="input-icon-container">
                        <i class="fa-brands fa-tiktok icontiktok"></i>
                        <input type="text" class="form-control" v-model="redesSociales.tiktok" placeholder="URL TikTok" />
                    </div>

                    <button v-if="redesSociales.tiktok !== redesSocialesOriginales.tiktok && redesSociales.tiktok" class="botonSocial" @click="actualizarTikTok">Actualizar</button>
                        <a class="botonSocial" v-show="redesSociales.tiktok && redesSociales.tiktok === redesSocialesOriginales.tiktok" :href="'https://www.tiktok.com/@' + redesSociales.tiktok " target="_blank">Ver</a>
                    </div>

                       <!-- ///////////////////////tripadvisor//////////////////////// -->

                       <div class="form-group">
                        <button  v-show="redesSocialesOriginales.tripadvisor" class="botonSocial" @click="eliminarTripAdvisor"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>

                     <div class="input-icon-container">
                        <img class="icontrip" src="../assets/images/tripadvisor-icon.png">
                        <input type="text" class="form-control" v-model="redesSociales.tripadvisor" placeholder="URL Tripadvisor" />
                    </div>

                        <button v-if="redesSociales.tripadvisor !== redesSocialesOriginales.tripadvisor && redesSociales.tripadvisor" class="botonSocial"  @click="actualizarTripAdvisor">Actualizar</button>
                        <a v-show="redesSociales.tripadvisor && redesSociales.tripadvisor === redesSocialesOriginales.tripadvisor" class="botonSocial" :href="redesSociales.tripadvisor" target="_blank">Ver</a>
                    </div>

                      <!-- ///////////////////////GOOGLE//////////////////////// -->

                      <div class="form-group">
                        <button v-show="redesSocialesOriginales.google"  class="botonSocial" @click="eliminarGoogle"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>

                     <div class="input-icon-container">
                        <i class="fa-brands fa-google icongoogle"></i>
                        <input type="text" class="form-control" v-model="redesSociales.google" placeholder="URL reseñas Google" />
                    </div>

                    <button v-if="redesSociales.google !== redesSocialesOriginales.google && redesSociales.google" class="botonSocial" @click="actualizarGoogle">Actualizar</button>
                        <a class="botonSocial" v-show="redesSociales.google && redesSociales.google === redesSocialesOriginales.google" :href="redesSociales.google" target="_blank">Ver</a>
                    </div>


                       <!-- ///////////////////////HASHTAG//////////////////////// -->
                       <div class="form-group">
                        <button v-show="redesSocialesOriginales.hashtag" class="botonSocial" @click="eliminarHashtag"><i class="fa-solid fa-trash" style="color: #565761;"></i></button>
                        <div class="input-icon-container">
                            <img class="iconHashtag" src="../assets/images/hashtag.png">
                            <input type="text" class="form-control" v-model="redesSociales.hashtag" placeholder="Hashtag sin #">

                        </div>
                        <button v-if="redesSociales.hashtag !== redesSocialesOriginales.hashtag && redesSociales.hashtag" class="botonSocial" @click="actualizarHashtag">Actualizar</button>
                    </div>
        </div> 
        <div v-else class="contenedor-centrado">
           <p  class="nohaydatos"> No hay datos que mostrar.</p>
        </div>
    </div>
</template>

<script>
  
import { auth } from "../firebase";
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert';

export default{
    data() {
        return {
            userUid: null,
            idNegocio: "",
            nombreNegocio: "",

            redesSociales: {
                instagram : "",
                facebook: "",
                whatsapp: "",
                tripadvisor: "",
                google: "",
                tiktok: "",
                web: "",
                hashtag: ""
            },
            Negocio: {
                nombre: "",
            },

            redesSocialesOriginales: {
                instagram : "",
                facebook: "",
                whatsapp: "",
                tripadvisor: "",
                google: "",
                tiktok: "",
                web: "",
                hashtag: ""
            },
        }
    },

    created() {
        this.initializeUser();
        this.idNegocio = localStorage.getItem('idNegocio');
        this.nombreNegocio =  localStorage.getItem('nombrenegocio');
        if (this.idNegocio && this.nombreNegocio ) {
        this.$store.commit('setidNegocio', this.idNegocio);
        this.$store.commit('setNombreNegocio', this.nombreNegocio);
        }
    },

    methods: {
        initializeUser() {
            auth.onAuthStateChanged(user => {
                if (user) {
                    this.userUid = user.uid;
                    this.obtenerDatosNegocio( this.idNegocio, this.userUid)
                    this.obtenerRedesSociales(this.idNegocio)
                } else {
                this.$router.push("/login");
                }
            });
        },
        async obtenerRedesSociales(id_negocio) {
            console.log(id_negocio)
            try {
                const response = await axios.get(`/datosRedesSociales/${id_negocio}`);
                console.log(response)
                if (response.data && response.data.length > 0) {
                    this.redesSociales = Object.assign({}, response.data[0]);
                    this.redesSocialesOriginales = Object.assign({}, response.data[0]);
                } else {
                    console.log("No se encontraron datos de redes sociales");
                }
            } catch (error) {
                console.error("Error al obtener los datos de redes sociales:", error);
            }
        },
  
        async eliminarInstagram() {
            try {
                await axios.post("/actualizarInstagram", { instagram: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.instagram = null,
                this.redesSocialesOriginales.instagram = null,
                this.$refs.alert.showAlert('success', '', 'Instagram eliminado.')
            } catch (err) {
                console.error("Error al actualizar instagram", err);
            }
        },

        async eliminarFacebook() {
            try {
                await axios.post("/actualizarFacebook", { facebook: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.facebook = null,
                this.redesSocialesOriginales.facebook = null,
                this.$refs.alert.showAlert('success', '', 'Facebook eliminado.')
            } catch (err) {
                console.error("Error al actualizar facebook", err);
            }
        },

        async eliminarWhatsApp() {
            try {
                await axios.post("/actualizarWhatsapp", { whatsapp: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.whatsapp = null,
                this.redesSocialesOriginales.whatsapp = null,
                this.$refs.alert.showAlert('success', '', 'Whatsapp actualizado.')
            } catch (err) {
                console.error("Error al actualizar whatsapp", err);
            }
        },

        async eliminarWeb() {
            try {
                await axios.post("/actualizarWeb", { web: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.web = null,
                this.redesSocialesOriginales.web = null,
                this.$refs.alert.showAlert('success', '', 'Web elliminada.')
            } catch (err) {
                console.error("Error al actualizar web", err);
            }
        },

        async eliminarTikTok() {
            try {
                await axios.post("/actualizarTikTok", { tiktok: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.tiktok = null,
                this.redesSocialesOriginales.tiktok = null,
                this.$refs.alert.showAlert('success', '', 'TikTok eliminado.')
            } catch (err) {
                console.error("Error al eliminar TikTok", err);
            }
        },

        async eliminarTripAdvisor() {
            try {
                await axios.post("/actualizarTripadvisor", { tripadvisor: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.tripadvisor = null,
                this.redesSocialesOriginales.tripadvisor = null,
                this.$refs.alert.showAlert('success', '', 'TripAdvisor eliminado.')
            } catch (err) {
                console.error("Error al eliminar TripAdvisor", err);
            }
        },

        async eliminarGoogle() {
            try {
                await axios.post("/actualizarGoogle", { google: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.google = null,
                this.redesSocialesOriginales.google = null,
                this.$refs.alert.showAlert('success', '', 'Google Reseñas eliminado.')
            } catch (err) {
                console.error("Error al eliminar Google", err);
            }
        },

        async eliminarHashtag() {
            try {
                await axios.post("/actualizarHashtag", { hashtag: null, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.redesSociales.hashtag = null,
                this.redesSocialesOriginales.hashtag = null,
                this.$refs.alert.showAlert('success', '', 'Hashtag eliminado.')
            } catch (err) {
                console.error("Error al actualizar hashtag", err);
            }
        },

        async actualizarInstagram() {
            try {
                await axios.post("/actualizarInstagram", { instagram: this.redesSociales.instagram, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'Instagram actualizado.')
            } catch (err) {
                console.error("Error al actualizar instagram", err);
            }
        },

        async actualizarFacebook() {
            try {
                await axios.post("/actualizarFacebook", { facebook: this.redesSociales.facebook, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)

                this.$refs.alert.showAlert('success', '', 'Facebook actualizado.')
            } catch (err) {
                console.error("Error al actualizar facebook", err);
            }
        },

        async actualizarWhatsApp() {
            try {
                await axios.post("/actualizarWhatsapp", { whatsapp: this.redesSociales.whatsapp, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'Whatsapp actualizado.')
            } catch (err) {
                console.error("Error al actualizar whatsapp", err);
            }
        },

        async actualizarWeb() {
            try {
                await axios.post("/actualizarWeb", { web: this.redesSociales.web, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'web actualizado.')
            } catch (err) {
                console.error("Error al actualizar web", err);
            }
        },

        async actualizarTikTok() {
            try {
                await axios.post("/actualizarTikTok", { tiktok: this.redesSociales.tiktok, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'Tiktok actualizado.')
            } catch (err) {
                console.error("Error al actualizar tiktok", err);
            }
        },

        async actualizarTripAdvisor() {
            try {
                await axios.post("/actualizarTripadvisor", { tripadvisor: this.redesSociales.tripadvisor, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'TripAdvisor actualizado.')
            } catch (err) {
                console.error("Error al actualizar tripadvisor", err);
            }
        },

        async actualizarGoogle() {
            try {
                await axios.post("/actualizarGoogle", { google: this.redesSociales.google, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'google actualizado.')
            } catch (err) {
                console.error("Error al actualizar google", err);
            }
        },

        async actualizarHashtag() {
            try {
                await axios.post("/actualizarHashtag", { hashtag: this.redesSociales.hashtag, usuario_uid: this.userUid , id_negocio: this.idNegocio});
                this.obtenerRedesSociales(this.idNegocio)
                this.$refs.alert.showAlert('success', '', 'Hashtag actualizado.')
            } catch (err) {
                console.error("Error al actualizar hashtag", err);
            }
        },
       
        async obtenerDatosNegocio(id_negocio, cliente_uid) {
            try {
                const response = await axios.post("/datoNegocio", { id_negocio, cliente_uid });
                this.Negocio.nombre = response.data[0].nombre
                console.log("obtengo datos del negocio,", this.Negocio)
            } catch (error) {
                console.error("Error al obtener los negocios:", error);
            }
        },
    },

    components: {
        VueBasicAlert
    }
}
</script>
  

<style scoped>
.input-icon-container {
    position: relative;
    width: 100%;
}
.caja1{
    display: flex;
    width: 96%;
    height: 62px;
    border-radius: 12px;
    background: #fff;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
    align-items: center;
}

.form-control{
    display: block;
    width: 100%;
    padding: 1.8rem 3rem;
    font-size: 20px;
    color: #495057;
    background-color: #f9fafc;
    background-clip: padding-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 12px;
    border: none;
    font-weight: 500;
}

.profileDatos{
    padding-top: 12px;
    padding-bottom: 4px;
}
.error-message {
    color: red;
    margin: 12px;
}
.profileDatos2{
    padding-top: 12px;
    padding-bottom: 4px;
}
.form-group-botton{
    display: flex;
    justify-content: flex-end;

}
.contenedor-centrado {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 80vh; /* Hace que el contenedor tenga todo el alto de la ventana */
  width: 100%; /* Asegura que el contenedor se expanda a todo el ancho disponible */
}

/* Estilos para .nohaydatos */
.nohaydatos {
  font-size: 20px;
  text-align: center;
  margin: 0; /* Elimina cualquier margen por defecto que pueda afectar el centrado */
}

.caja2{
    width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;

}
.botonSocial{
    cursor: pointer;
    color: rgb(86, 87, 97);
    padding: 0.8rem;
    font-size: 18px;
    font-weight: 500;
    background-color: white;
    border: none;
}

.nombrenegocio{
  color: rgb(86, 87, 97);
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    padding: 8px;
    text-align: center;
    text-decoration: underline;
}


.section{
    height: auto;
}
.section h2 {
    cursor: pointer;
    padding: 18px;
    font-size: 24px;
    border-radius: 12px;
    margin-top: 20px;
    color: #0097B2;
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    transition: background-color 0.3s ease;
    justify-content: space-between;
    align-items: baseline;
}
.iconHashtag{
    height: 30px;
    width: 30px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.btn-add {
    padding: 0.5rem 1.1rem;
    font-size: 18px;
    cursor: pointer;
    color: #fff;
    background: #0097B2;
    border-radius: 12px;
}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    justify-content: center;
}


.container-left {
    display: flex;
  width: 100%;
  border-radius: 12px;
  justify-content: center;
  margin-top: 20px;
  align-items: baseline;
  margin-bottom: 25px;
}
.router-link {
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    outline: none;

}

.router-link.router-link-active {
    border-radius: 12px;
    padding: 6px;
    color:black;
    text-align: center;
    width: 100%;
    outline: none;
    font-size: 24px;
    font-weight: 500;
    box-shadow: 0px 0px 2px 0px #000000;
}


.form-group {
    margin-bottom: 1rem;
    display: flex;
    align-items: baseline;
}

.form-group0{
    margin-bottom: 1rem;

}

.iconInstagram {
    color: #E1306C;
    font-size: 32px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.iconWhatsapp {
    color: #25D366;
    font-size: 32px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.iconFacebook {
    color: #1877F2;
    font-size: 30px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.iconweb {
    color: #000000;
    font-size: 28px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.icontiktok{
    color: #000000;
    font-size: 28px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}
.icontrip{
    color: #000000;
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    height: 32px;
    width: 32px;
}
.icongoogle{
    color: #4285F4;
    font-size: 28px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.error-mess {
    font-size: 16px;
    color: rgb(243, 47, 47);
    margin-left: 6px;
}
/* Estilos para dispositivos móviles */
@media only screen and (max-width: 600px) {
   .row{
    padding: 0px;
    margin: 0;

   }
    form .form-control {
        padding: 1.8rem 0.8rem;
        font-size: 18px;
    }

  
}

/* Estilos para tabletas y dispositivos de escritorio */
@media only screen and (min-width: 601px) {
     form .form-control {
        padding: 2rem 1.2rem;
        font-size: 1.6rem;
    }

  
}
@media only screen and (max-width: 100px) {
  .row {
  
  }

  .caja1{
    width: 100%;
  }
  .caja2{
  width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;

}
}

@media (min-width: 1024px) {
    .router-link {
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
    outline: none;

}

.router-link.router-link-active {
    border-radius: 12px;
    padding: 6px;
    color:black;
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-weight: 500;
    margin-left: 6px;
    box-shadow: 0px 0px 2px 0px #000000;
    outline: none;

}


    .caja2{
  width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;

}
.row{
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }
  .caja1{
    width: 60%;
    margin-bottom: 30px;
  }

  .caja2{
    width: 60%;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;
  }
}
</style>