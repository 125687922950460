<template>
  <div class="tienda">
    <div class="modelos">
      <div v-for="modelo in modelos" :key="modelo.id" class="modelo" @click="seleccionarModelo(modelo)">
        <img class="imagen" :src="require(`../assets/images/${modelo.imagen}`)" alt="" />
      </div>
    </div>
    <!-- Sección para mostrar el modelo seleccionado -->
    <div v-if="modeloSeleccionado" class="modelo-seleccionado">
      <div class="container1">
        <img :src="require(`../assets/images/${modeloSeleccionado.imagen}`)" alt="" class="imagen">
      </div>
      <div class="container2">
        <p class="modeloSeleccionadoTittle" :style="{ color: modeloSeleccionado.color }">
          {{ modeloSeleccionado.nombre }}
        </p>

        <p class="modeloSeleccionadoTittle1">
          {{ modeloSeleccionado.titulo1 }}
        </p>

        <div class="input-icon-container">
          <i class="fab fa-instagram iconInstagram"></i>
          <input type="text" class="form-control" v-model="urlUsuario" placeholder="Nombre de usuario">
        </div>
        <div style="width: 100%; margin-top: 8px;" v-for="(user, index) in urlUsuarios" :key="index">
          <div class="input-icon-container">
            <i class="fab fa-instagram iconInstagram"></i>
            <input type="text" class="form-control" v-model="urlUsuarios[index]" :placeholder="'Nombre de usuario ' + (index + 2)">
          </div>
        </div>
        <div v-if="cantidad > 1" class="añadir-mas-usuarios">
          <button class="botonañadir" @click="añadirUsuarioInstagram">Añadir otros usuarios</button>
        </div>



        <div class="footer" :style="{ backgroundColor: modeloSeleccionado.color }">
          <div class="footer0" :style="{ backgroundColor: modeloSeleccionado.color }" >
            <button  :style="{ backgroundColor: modeloSeleccionado.color, border: 'none'  }" :disabled="cantidad === 1" @click="decrementarCantidad"><i class="fa-solid fa-minus iconminus"></i></button>
              <span :style="{ color: modeloSeleccionado.color}"  class="units">{{ cantidad }}</span>
            <button :style="{ backgroundColor: modeloSeleccionado.color, border: 'none'  }" :disabled="cantidad === 10" @click="incrementarCantidad"><i class="fa-solid fa-plus iconplus"></i></button>
          </div>

        <div class="footer1" @click="añadirAlCarrito()">
          <button :style="{ color: modeloSeleccionado.color}" class="btnF" >Añadir</button>
            <p :style="{ color: modeloSeleccionado.color}" class="priceFooter">{{ (modeloSeleccionado.precio * cantidad).toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}</p>
          </div>
        </div>
      </div>
      <div v-if="cantidad > 0" class="carrito">
    <router-link to="/pedido">
      <div v-if="carrito.length > 0" class="cart-icon-wrapper">
        <div class="icon-container" :style="{ backgroundColor: modeloSeleccionado.color }">
          <i class="fa-solid fa-bag-shopping"></i>
          <div class="unit-count" :style="{ backgroundColor: modeloSeleccionado.color }" >{{ totalCantidad }}</div>
        </div>
      </div>
    </router-link>
  </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";

export default {
  data(){
    return {
      modelos : [],
      modeloSeleccionado: null,
      urlUsuario: '',
      cantidad: 1,
      urlUsuarios: [] 
    };
  },

  created()
  {
    this.obtenerModelos();
    this.$store.dispatch('carrito');

  },

  watch: {
  cantidad(newVal) {
    // Resetear los campos de usuario de Instagram si la cantidad se reduce
    if (newVal < this.urlUsuarios.length + 1) {
      this.urlUsuarios = [];
    }
  }
},

computed: {

  carrito() {
    return this.$store.state.carrito
},

totalCantidad() {
return this.carrito.reduce((total, item) => total + item.cantidad, 0);
},

calcularPrecioTotal() {
    return this.carrito.reduce((total, item) => total + item.precio, 0);
},
isAddButtonDisabled() {
    // Verifica si urlUsuario está vacío
    const isUrlUsuarioEmpty = !this.urlUsuario.trim();

    // Verifica si todos los elementos en urlUsuarios están vacíos
    const areAllUrlUsuariosEmpty = this.urlUsuarios.every(urlUsuario => !urlUsuario.trim());

    // El botón está desactivado si urlUsuario está vacío Y todos los elementos en urlUsuarios también lo están
    return isUrlUsuarioEmpty && areAllUrlUsuariosEmpty;
  },
},

  methods: {
    async obtenerModelos() {
      axios.get('/modelos') // Asegúrate de que esta URL sea correcta para tu API
        .then(response => {
          // Suponiendo que tienes una mutación 'setModelos' en tu store de Vuex para actualizar los modelos
          console.log(response)
          this.modelos = response.data
          this.modeloSeleccionado = this.modelos[0];

          console.log(this.modelos)
        })
        .catch(error => console.error('Error al obtener modelos:', error));
    },
    seleccionarModelo(modelo) {
    this.modeloSeleccionado = modelo;
  },

  verCarrito() {
    // Implementa la lógica para mostrar el carrito, por ejemplo:
    console.log('Mostrando carrito');
  },

  decrementarCantidad() {
    if (this.cantidad > 1) { // Asegúrate de que la cantidad no baje de 1
      this.cantidad--;
      this.ajustarCamposUsuario(); // Llama a ajustarCamposUsuario después de disminuir la cantidad
    }
  },

  // Método para ajustar los campos de entrada basado en la cantidad actual
  ajustarCamposUsuario() {
    // Si hay más campos de usuario que la cantidad deseada, elimina el excedente
    while (this.urlUsuarios.length > this.cantidad - 1) {
      this.urlUsuarios.pop();
    }
  },

  // Método para incrementar la cantidad
  incrementarCantidad() {
    if (this.cantidad < 10) { // Asegúrate de establecer un límite máximo según tus necesidades
      this.cantidad++;
      // No es necesario ajustar los campos de usuario al incrementar la cantidad,
      // pero si necesitas hacer algo específico aquí, añádelo
    }
  },


  añadirAlCarrito() {
  let precio = this.precios();
  const links = [this.urlUsuario, ...this.urlUsuarios];

  const item = {
    id: this.modeloSeleccionado.id_modelo,
    nombre: this.modeloSeleccionado.nombre,
    cantidad: this.cantidad,
    precio: precio,
    links: links.slice(0, this.cantidad),
    color: this.modeloSeleccionado.color
  };
  this.$store.dispatch('agregarItemAlCarrito', item);
  console.log(item);
},


  precios()
        {
           return (this.modeloSeleccionado.precio) * this.cantidad;
        },

        añadirUsuarioInstagram() {
  if (this.urlUsuarios.length < this.cantidad - 1) {
    this.urlUsuarios.push(''); // Añade campos para usuarios adicionales
  }
},
  actualizarInstagramUsers() {
    // Añadir campos si la cantidad es mayor que la longitud de urlUsuarios más uno (por urlUsuario)
    if (this.cantidad > this.urlUsuarios.length + 1) {
      let camposAñadir = this.cantidad - 1 - this.urlUsuarios.length;
      for (let i = 0; i < camposAñadir; i++) {
        this.urlUsuarios.push('');
      }
    } else if (this.cantidad < this.urlUsuarios.length) { // Reducir campos si la cantidad es menor
      // Asegura que solo se elimine un campo a la vez
      this.urlUsuarios.pop();
      this.cantidad--;
    }
  },

  },
  filters: {
    currency(value) {
      return `$${value.toFixed(2)}`;
    },
  },
};
</script>

<style>

.imagen{
  width: 100%;
    height: auto;
}
.añadir-mas-usuarios{
  width: 100%;
}
.botonañadir{
  padding-top: 8px;
    padding-bottom: 8px;
    border: none;
    background-color: white;
    font-size: 16px;
    text-align: start;
    display: block;
    color: #495057;
    background-color: #f9fafc;
    background-clip: padding-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 12px;
    border: none;
    font-weight: 500;
}
.modelos {
  display: flex;
    overflow-x: auto;
    margin: 12px
  
}
.cantidad{
  font-size: 20px;
}
.priceFooter{
  margin-bottom: 0;
}
.entradaTexto{
  border-radius: 12px;
}
.cantidad-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}
.iconminus{
font-size: 27px;
color: white;
}
.iconplus{

font-size: 27px;
color: white;

}


.modelo-seleccionado{
  display: flex;
    flex-wrap: wrap;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    width: 96%;
    margin-top: 12px;
    margin-right: auto;
    border-radius: 12px;
    margin-left: auto;
    padding: 6px;
    justify-content: center;
}
.modeloSeleccionadoTittle1{
  font-size: 18px;
    margin-bottom: 6px;
    font-weight: 500;
    padding: 2px;
    text-align: center;
}

.modelo img {
  width: 120px;
}
.modelo-seleccionado img {
}
.units{
  text-align: center;
    background-color: #fff;
    border-radius: 31px;
    padding: 0;
    width: 12%;
    font-weight: 500;
    font-size: 25px;
}
.btnF{
  margin: 0;
    padding: 0;
    margin-right: 14px;
    background-color: white;
    border: none;
    font-weight: 500;
}

.cantidad-selector {
  display: flex;
  align-items: center;
  justify-content: center;
}

.cart-icon-wrapper {
  position: fixed; /* O absolute, dependiendo de cómo quieras posicionarlo */
  right: 20px; /* Ajusta según tus necesidades */
  bottom: 20px; /* Ajusta según tus necesidades */
}

.icon-container {
  position: relative;
    width: 60px;
    height: 60px;
    background-color: #007bff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.fa-bag-shopping {
  color: white;
    font-size: 30px;
}

.unit-count {
  position: absolute;
    top: -15px;
    left: -11px;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 18px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}


.container1{
  width: 60%;
}
.container2{
  width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 12px;
    justify-content: center;

}
.filtros button {
  margin-right: 5px;
}

.footer{
  width: 100%;
  display: block;
    padding: 4px;
    border-radius: 12px;
    margin-top: 12px;
}

.footer0{
  width: auto;
    height: auto;
    margin: 6px;
    padding: 0;
    display: flex;
    border-radius: 12px;
    color: #fff;
    align-items: baseline;
    justify-content: space-evenly;
}
.footer1{
  width: auto;
    height: auto;
    font-size: 26px;
    padding: 5px;
    margin: 6px;
    display: flex;
    border-radius: 12px;
    color: #000;
    cursor: pointer;
    background-color: #fff;
    justify-content: center;
    align-items: flex-start;
    font-weight: 500;
}
.productos {
  display: flex;
  flex-wrap: wrap;
}
.seleccionadotext{
  font-size: 18px;
}
.modeloContainer{
  display: block;
}

.producto {
  margin: 10px;
  text-align: center;
}
.modeloSeleccionadoTittle{
  font-size: 24px;
    margin-bottom: 8px;
    font-weight: 600;
}

.input-icon-container {
    position: relative;
    width: 100%;
}


.iconInstagram {
    color: #E1306C;
    font-size: 32px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
}

.form-control{
  display: block;
    width: 100%;
    padding: 1.8rem 3rem;
    font-size: 20px;
    color: #495057;
    background-color: #f9fafc;
    background-clip: padding-box;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 12px;
    border: none;
    font-weight: 500;
}
.carrito {
  position: fixed; /* Posicionamiento fijo respecto a la ventana del navegador */
  right: 20px; /* Espaciado desde el lado derecho */
  bottom: 20px; /* Espaciado desde la parte inferior */
}

.carrito button {
  width: 50px; /* Tamaño del botón */
  height: 50px; /* Tamaño del botón */
  border-radius: 50%; /* Redondea los bordes para formar un círculo */
  background-color: #007bff; /* Color de fondo */
  color: white; /* Color del icono */
  border: none; /* Elimina el borde */
  display: flex; /* Permite usar justify-content y align-items */
  justify-content: center; /* Centra horizontalmente el contenido */
  align-items: center; /* Centra verticalmente el contenido */
  cursor: pointer; /* Cambia el cursor a una mano para indicar que es clickeable */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Opcional: añade sombra para resaltar el botón */
}

.carrito button:focus {
  outline: none; /* Elimina el contorno cuando el botón está enfocado */
}

</style>
