<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />
    <div class="row">
        <div class="caja1">
            <router-link class="router-link" to="/datos">Datos</router-link>
            <router-link class="router-link" to="/redes">Redes</router-link>
            <router-link class="router-link" to="/pdf">PDF</router-link>
        </div>
        <div class="caja2" v-if="Negocio && Negocio.nombre">
            <div class="section">
                <h2 class="h2datos" @click="toggleDatosNegocio">Datos Negocio <i v-if="mostrarDatosNegocio" class="fa-solid fa-arrow-up"></i><i v-else class="fa-solid fa-arrow-down"></i></h2>
                <div class="profileDatos" v-if="mostrarDatosNegocio">
                    <form @submit.prevent="actualizarDatosNegocio">
                        <div class="form-group">
                            <input class="form-control" type="text" id="nombre_negocio" v-model="Negocio.nombre" required maxlength="50">
                        </div>

                        <div class="form-group0">
                            <input  class="form-control" type="text" id="direccion" v-model="Negocio.direccion" required maxlength="250">
                        </div>
                    </form>
                </div>
            </div>
            <div class="section">
                <h2 class="h2datos" @click="toggleDatosCliente">Datos Facturación <i v-if="mostrarDatosFacturacion" class="fa-solid fa-arrow-up"></i><i v-else class="fa-solid fa-arrow-down"></i></h2>
                    <div class="profileDatos" v-if="mostrarDatosFacturacion">
                        <form @submit.prevent="actualizarDatosFacturacion">
                            <div class="form-group">
                                <input class="form-control" type="text" id="dni" v-model="Cliente.dni" required maxlength="25">
                            </div>

                            <div class="form-group0">
                                <input  class="form-control" type="text" id="nombre" v-model="Cliente.nombre" required maxlength="25">
                            </div>

                            <div class="form-group0">
                                <input class="form-control"  ref="autocompleteInput" type="text" id="domicilio" v-model="Cliente.direccion" @focus="initAutocomplete" required maxlength="100">
                            </div>
                        </form>
                    </div>
            </div>

            <div class="section">
                <h2 class="h2datos" @click="toggleDatosSuscripcion">Datos Suscripcion <i v-if="mostrarDatosSuscripion" class="fa-solid fa-arrow-up"></i><i v-else class="fa-solid fa-arrow-down"></i></h2>
                    <div class="profileDatos" v-if="mostrarDatosSuscripion">
                <div class="form-group-2">
                    <p class="textSuscripcion" v-if="Suscripcion.estado === 1"> Suscripción <i class="fa-solid fa-circle" style="color: green;"></i></p>
                    <p class="textSuscripcion" v-if="Suscripcion.estado === 0"> Suscripción <i class="fa-solid fa-circle" style="color: red;"></i></p>

                    <div class="form-group-3">
                        <p class="fechainicio">Fecha inicio:</p>
                        <p class="form-control2"> {{ new Date(Suscripcion.fecha_inicio).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</p>                
                    </div>

                    <div class="form-group-3">
                        <p class="fechafinal">Fecha final:</p>
                        <p class="form-control2"> {{ new Date(Suscripcion.fecha_final).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) }}</p>                
                    </div>

                    <div class="form-group-3">
                        <p>La renovación se efectuará el día {{ new Date(Suscripcion.fecha_final).toLocaleDateString('es-ES', { day: '2-digit', month: '2-digit', year: 'numeric' }) }} con un coste de {{ Suscripcion.precio_base.toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}/año </p>
                    </div>

                    <button class="btn-unsubscribe" @click="showUnsubscribeModal=true">Dar de baja la suscripción</button>

                </div>
                    </div>     
            </div>
        </div>
        <div v-else class="contenedor-centrado">
           <p class="nohaydatos"> No hay datos que mostrar.</p>
        </div>
        <div v-if="showUnsubscribeModal" class="quickview-modal">
            <h2>¡Vas a dar de baja la suscripción de {{ Negocio.nombre }}!</h2>
            <p>Se eliminarán todos los datos asociados al negocio y se perderá el acceso desde hoy mismo.</p>
            <button @click="confirmUnsubscribe">Confirmar baja</button>
            <button @click="showUnsubscribeModal=false">Cancelar</button>
        </div>
    </div>
</template>

<script>
  
import { auth } from "../firebase";
import axios from "axios";
import VueBasicAlert from 'vue-basic-alert';

export default{
    data() {
        return {
            Cliente: {
                dni: '',
                nombre: '',
                apellidos: '',
                direccion: ''
            },
            dniInvalido: false,
            userUid: null,
            showUnsubscribeModal: false,
            Usuario: {
                nombre: "",
                email: "",
                contraseña: ""
            },
            
            Negocio: {
                nombre: '',
                direccion: ''
            },

            Suscripcion: {
                id_suscripcion: "",
                precio_base : "",
                fecha_inicio: "",
                fecha_final: "",
                estado: "",
            },


            errorObj: {
                email: "",
                password: "",
            },

            updateError: null,
            showProfile: false,
            showSocial: false,
            mostrarDatosFacturacion: false,
            mostrarDatosSuscripion: false,
            mostrarDatosNegocio: false,
            idNegocio: "",
            nombreNegocio: ""
        }
    },
    created() {
        
     this.idNegocio = localStorage.getItem('idNegocio');
     this.nombreNegocio =  localStorage.getItem('nombrenegocio');
        if (this.idNegocio && this.nombreNegocio ) {
            this.$store.commit('setidNegocio', this.idNegocio);
            this.$store.commit('setNombreNegocio', this.nombreNegocio);
        }
    this.initializeUser();
   
  },

    methods: {
        initAutocomplete() {
        const input = this.$refs.autocompleteInput;
        if (!input) return;

        this.autocomplete = new google.maps.places.Autocomplete(input, {
            types: ["address"], // Puedes ajustar los tipos de lugar aquí
            componentRestrictions: { country: "es" } // Opcional: Restricciones de país
        });

        this.autocomplete.addListener("place_changed", this.onPlaceChanged);
    },
    
    confirmUnsubscribe() {
        this.eliminarDatosCliente(this.idNegocio, this.userUid)
            .then(() => {
                this.showUnsubscribeModal = false;
                this.eliminarNegocio(this.idNegocio, this.userUid);
                this.eliminarSuscripcion(this.idNegocio, this.userUid);
                this.eliminarRedesSociales(this.idNegocio, this.userUid);
                this.eliminarCartaES(this.idNegocio, this.userUid)
                this.$router.push({ path: '/negocios', query: { success: true } });
            })
            .catch(error => {
                console.error("Error al eliminar el cliente:", error);
            });
    },
    onPlaceChanged() {
        const place = this.autocomplete.getPlace();
        if (place.geometry) {
            // Aquí puedes hacer algo con los datos del lugar seleccionado.
            // Por ejemplo, extraer la dirección, latitud, longitud, etc.
            this.Cliente.direccion = place.formatted_address;
        }
    },

    goBack() {
            this.$router.go(-1);
        },
        initializeUser() {
            auth.onAuthStateChanged(user => {
                if (user) {
                    this.userUid = user.uid;
                    this.Usuario.email = user.email;
                    this.obtenerDatosFacturacion(this.idNegocio, user.uid);
                    this.obtenerDatosNegocio(this.idNegocio, user.uid);
                    this.obtenerDatosSuscripcion(this.idNegocio, user.uid);
                } else {
                this.$router.push("/login");
                }
            });
        },

        toggleProfile() {
        this.showProfile = !this.showProfile;
        },
        toggleDatosCliente() {
        this.mostrarDatosFacturacion = !this.mostrarDatosFacturacion;
        },
        toggleDatosNegocio() {
        this.mostrarDatosNegocio = !this.mostrarDatosNegocio;
        },
        toggleDatosSuscripcion() {
        this.mostrarDatosSuscripion = !this.mostrarDatosSuscripion;
        },

        showErrorMessages() {
            let hasErrors = false;
  
            if (this.userData.password.length > 0 && this.userData.password.length < 6) {
                this.errorObj.password = "La contraseña debe tener al menos 6 dígitos.";
                hasErrors = true;
            }
  
            if (this.userData.password.length > 50) {
                this.errorObj.password = "La contraseña debe tener como máximo 50 caracteres.";
                hasErrors = true;
            }
  
            if (this.userData.email.length > 0) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(this.userData.email)) {
                    this.errorObj.email = "Correo electrónico no válido.";
                    hasErrors = true;
                }
            }
  
            return !hasErrors;
        },
  
        actualizarError(campo) {
            this.errorObj[campo] = "";
        },
  
        async updateProfile() {
            if (this.showErrorMessages()) {
                try {
                    await this.$store.dispatch("updateProfile", {
                        name: this.userData.nombre,
                        email: this.userData.email,
                        password: this.userData.password
                    });

                    await axios.put("/nombre", { 
                        nombre: this.userData.nombre, 
                        user_uid: this.userUid 
                    });
                    this.$refs.alert.showAlert('success', '', 'Perfil actualizado.')

                } catch (error) {
                    this.updateError = "Por seguridad, vuelva a iniciar sesión e intente cambiar los datos.";
                }
            }   
        },
        async obtenerDatosFacturacion(id_negocio, cliente_uid) {
            try {
                const response = await axios.post("/datosCliente", { id_negocio, cliente_uid });
                console.log(response)
                this.Cliente.dni = response.data[0].dni;
                this.Cliente.nombre = response.data[0].nombre;
                this.Cliente.direccion = response.data[0].direccion;   
                console.log("obtengo datos de clie,", this.Cliente)
              
            } catch (error) {
                console.error("Error al obtener los datos de facturación:", error);
            }
        },
        async obtenerDatosNegocio(id_negocio, cliente_uid) {
            try {
                const response = await axios.post("/datoNegocio", { id_negocio, cliente_uid });
                this.Negocio.nombre = response.data[0].nombre;
                this.Negocio.direccion = response.data[0].direccion;    
            } catch (error) {
                console.error("Error al obtener los negocios:", error);
            }
        },
        async obtenerDatosSuscripcion(id_negocio, cliente_uid) {
            try {
                const response = await axios.post("/datosSuscripcion", { id_negocio, cliente_uid });            
                this.Suscripcion.precio_base = response.data[0].precio_base;
                this.Suscripcion.fecha_inicio = response.data[0].fecha_inicio;
                this.Suscripcion.fecha_final = response.data[0].fecha_final;
                this.Suscripcion.estado = response.data[0].estado;
                console.log("obtengo datos de suscripcion,", this.Suscripcion)
            } catch (error) {
                console.error("Error al obtener los datos de suscrirpcion", error);
            }
        },


        async actualizarDatosFacturacion() {
            if (!this.dniInvalido) {
                try {
                const response = await axios.put("/actualizarCliente", {
                    cliente_uid: this.userUid, 
                    dni: this.Cliente.dni,
                    nombre: this.Cliente.nombre,
                    direccion: this.Cliente.direccion,
                });

                if (response.data) {
                    console.log('Datos actualizados con éxito');
                }
            } catch (error) {
                console.error("Error al actualizar los datos del cliente:", error);
            }
            } else {
                alert('Por favor, introduzca un DNI válido.');
            }
        },

        async eliminarDatosCliente(id_negocio, cliente_uid) {
            try {
                const clienteData = { id_negocio, cliente_uid };
                const response = await axios.post("/eliminarCliente", clienteData);
                console.log("Cliente eliminado con éxito:", response.data);                
            } catch (error) {
                console.error("Error al eliminar el cliente:", error);
                throw error; // Esto asegura que el error pueda ser manejado en el .catch() del llamado a confirmUnsubscribe
            }
        },

        async eliminarNegocio(id_negocio, cliente_uid) {
            try {
                await axios.post("/eliminarNegocio", { id_negocio, cliente_uid });
                console.log("Negocio eliminado con éxito");
            } catch (error) {
                console.error("Error al eliminar el negocio:", error);
            }
        },
        async eliminarSuscripcion(id_negocio, cliente_uid) {
            try {
                await axios.post("/eliminarSuscripcion", { id_negocio, cliente_uid });
                console.log("Suscripción eliminada con éxito");
            } catch (error) {
                console.error("Error al eliminar la suscripción:", error);
            }
        },
        async eliminarRedesSociales(id_negocio, usuario_uid) {
            try {
                await axios.post("/eliminarRedesSociales", { id_negocio, usuario_uid });
                console.log("Redes sociales eliminadas con éxito");
            } catch (error) {
                console.error("Error al eliminar las redes sociales:", error);
            }
        },
        async eliminarCartaES(id_negocio, cliente_uid) {
            try {
                await axios.post("/eliminarCartaURL", { id_negocio, cliente_uid });
                console.log("Carta en español eliminada con éxito");
            } catch (error) {
                console.error("Error al eliminar la carta en español:", error);
            }
        },

        async actualizarDatosNegocio() {
    try {
        // Asegúrate de que el endpoint en tu backend esté configurado para aceptar solicitudes PUT
        const url = "/actualizarNegocio";
        const negocioData = {
            nombre: this.Negocio.nombre, // El nuevo nombre para el negocio
            id_negocio: this.idNegocio, // El ID del negocio que se está actualizando
            cliente_uid: this.userUid, // El UID del cliente asociado con este negocio
        };

        console.log(negocioData)
        // Usando axios.put para enviar la solicitud de actualización
        const response = await axios.put(url, negocioData);

        console.log("Negocio actualizado con éxito:", response.data);
        this.$refs.alert.showAlert('success', '', 'Nombre del negocio actualizado con éxito.');

        // Opcionalmente, refresca los datos del negocio en la interfaz de usuario
        this.obtenerDatosNegocio(this.idNegocio, this.userUid);
    } catch (error) {
        console.error("Error al actualizar el negocio:", error);
        this.$refs.alert.showAlert('error', '', 'Error al actualizar el nombre del negocio.');
    }
}




    },
    components: {
        VueBasicAlert
    }
}
</script>
  

<style scoped>

.fechainicio{
    margin: 0;
}

.caja1{
    display: flex;
    width: 96%;
    height: 62px;
    border-radius: 12px;
    background: #fff;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 15px;
    align-items: center;
}
.fechafinal{
    margin: 0;
}
/* Estilos para el contenedor padre de .nohaydatos */
.contenedor-centrado {
  display: flex;
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 80vh; /* Hace que el contenedor tenga todo el alto de la ventana */
  width: 100%; /* Asegura que el contenedor se expanda a todo el ancho disponible */
}

/* Estilos para .nohaydatos */
.nohaydatos {
  font-size: 20px;
  text-align: center;
  margin: 0; /* Elimina cualquier margen por defecto que pueda afectar el centrado */
}

.profileDatos{
    padding-top: 12px;
    padding-bottom: 4px;
}
.form-group-2{
    display: block;
    width: 100%;
    color: #565761;
    background-color: #f9fafc;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    border-radius: 12px;
    padding: 14px;
    font-size: 18px;
}
.form-group-3{
    display: flex;
    align-items: baseline;
    margin: 0;
}
.error-message {
    color: red;
    margin: 12px;
}

.form-group-botton{
    display: flex;
    justify-content: flex-end;

}

.textSuscripcion{
    font-size: 20px;
}

.section{
    height: auto;
}

.btn-unsubscribe {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 12px;
}

.quickview-modal {
    /* Estilos básicos para tu modal */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%
}

.quickview-modal button {
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 12px;
    font-size: 20px;
}
.negocioNOHAY{
    font-size: 22px;
    text-align: center;
}

.h2datos{
    cursor: pointer;
    padding: 18px;
    font-size: 24px;
    border-radius: 12px;
    color: rgb(86, 87, 97);
    display: flex;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    transition: background-color 0.3s ease;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 12px
}

.caja2{
    width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-right: 10px;
    margin-left: 10px;
    margin-bottom: 30px;

}
.row {
    display: flex;
    flex-wrap: wrap;
    padding: 50px;
    justify-content: center;
}

.router-link {
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 24px;
    text-align: center;
    font-weight: 500;
    outline: none;

}

.router-link.router-link-active {
    border-radius: 12px;
    padding: 6px;
    color:black;
    text-align: center;
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    margin-left: 6px;
    box-shadow: 0px 0px 2px 0px #000000;
    outline: none;

}


.form-control {

    display: block;
    width: 100%;
    color: #565761;
    padding: 1.5rem;
    font-size: 18px;
}

.form-control2 {
    display: block;
    width: auto;
    color: #565761;
    font-size: 18px;
    padding-left: 12px;
}

.form-group {
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
}

.form-group0{
    margin-bottom: 1rem;

}

.error-mess {
    font-size: 16px;
    color: rgb(243, 47, 47);
    margin-left: 6px;
}
/* Estilos para dispositivos móviles */

@media only screen and (max-width: 600px) {
    .btn-unsubscribe {
     background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 12px;
}

.quickview-modal {
    /* Estilos básicos para tu modal */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 96%;
}

.quickview-modal button {

    margin-top: 20px;
    margin-right: 10px;
    border-radius: 12px;
    font-size: 20px;
}
  .row {
    padding: 0px;
    margin: 0;
  }

  .caja1{
    width: 100%;
  }
  .caja2{
  width: 96%;
    background: #fff;
    border-radius: 12px;
    padding-top: 8px;
    padding-bottom: 8px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;

}
}

@media (min-width: 1024px) {
    .btn-unsubscribe {
     background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
    border-radius: 12px;
}

.quickview-modal {
    /* Estilos básicos para tu modal */
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    z-index: 1000;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 50%;
}

.quickview-modal button {
    margin-top: 20px;
    margin-right: 10px;
    border-radius: 12px;
    font-size: 20px;
}
    .router-link {
    border-radius: 12px;
    padding: 6px;
    color: rgb(86, 87, 97);
    width: 100%;
    font-size: 28px;
    text-align: center;
    font-weight: 500;
    outline: none;

}

.router-link.router-link-active {
    border-radius: 12px;
    padding: 6px;
    color:black;
    text-align: center;
    width: 100%;
    font-size: 28px;
    font-weight: 500;
    margin-left: 6px;
    box-shadow: 0px 0px 2px 0px #000000;
    outline: none;

}


  .row{
    display: flex;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0;
  }
  .caja1{
    width: 60%;
    margin-bottom: 30px;
  }

  .caja2{
    width: 60%;
    background: #fff;
    border-radius: 12px;
    padding: 16px;
    height: auto;
    margin: 0;
    margin-bottom: 30px;
  }
}
</style>