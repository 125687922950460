<template>
    <div class="section">
      <h2 class="h2datos" v-if="!Usuario.nombre && !Usuario.email">
    
    </h2>
      <div class="profileDatos" v-if="showProfile">
        <form @submit.prevent="updateProfile">
          <div class="form-group0">
            <input type="text" :placeholder="Usuario.nombre" class="form-control" v-model="Usuario.nombre" maxlength="20"/>
          </div>
  
          <div class="form-group0">
            <p class="error-mess" v-if="errorObj.email">{{ errorObj.email }}</p>
            <input type="text" :placeholder="Usuario.email" class="form-control" v-model="Usuario.email" @input="actualizarError('email')" />
          </div>
  
          <div class="form-group0">
            <p class="error-mess" v-if="errorObj.password">{{ errorObj.password }}</p>
            <input type="password" class="form-control" v-model="Usuario.contraseña" placeholder="Contraseña" @input="actualizarError('password')" />
          </div>
  
          <div class="form-group-botton">
            <button type="submit" class="btn-add">Cambiar datos</button>
          </div>
        </form>
        <div v-if="updateError" class="error-message">
          {{ updateError }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        Usuario: {
          nombre: "",
          email: "",
          contraseña: ""
        },
        showProfile: false,
        errorObj: {
          email: "",
          password: ""
        },
        updateError: null
      };
    },
    methods: {
      toggleProfile() {
        this.showProfile = !this.showProfile;
      },
      actualizarError(campo) {
        this.errorObj[campo] = "";
      },
      updateProfile() {
        // Implementa la lógica para actualizar el perfil aquí
      }
    }
  }
  </script>
  
  <style scoped>
  /* Añade estilos específicos para este componente aquí */
  .form-group0 {
    margin-bottom: 15px;
  }
  
  .error-message {
    color: red;
  }
  
  .btn-add {
    /* Estilos para tu botón de 'Cambiar datos' */
  }
  </style>
  