<template>
  <div class="container">
    <h3 class="crearcuenta">Crear cuenta</h3>

    <button class="googleinit" @click="iniciarSesionConGoogle">
      <img class="iconGoogle" :src="require('@/assets/images/google.png')">
      <p class="google">Registrar con Google</p>
    </button>
    
    <button class="googleinit" @click="iniciarSesionConGoogle">
      <i class="fa-brands fa-apple iconApple"></i>
      <p class="google">Registrar con Apple</p>
    </button>

    <div class="login-divider">
      <div class="line"></div>
      <div class="or-circle">o</div>
      <div class="line"></div>
    </div>

    <form @submit.prevent="doRegister" novalidate autocomplete="off">
        <div class="registroForm">
          <input type="text" placeholder="Nombre" class="registroFormImput" v-model="userData.name" @input="actualizarError('name')" maxlength="25" required/>
          <p class="error-mess" v-if="errorObj.name.length > 0">{{ errorObj.name[0] }}</p>
        </div>

        <div class="registroForm">
          <input type="email" placeholder="Correo electrónico" class="registroFormImput" v-model="userData.email" @input="actualizarError('email')" maxlength="50"/>
          <p class="error-mess" v-if="errorObj.email.length > 0">{{ errorObj.email[0] }}</p>
        </div>

        <div class="registroForm">
          <input :type="showPassword ? 'text' : 'password'" placeholder="Contraseña" class="registroFormImput" v-model="userData.password" @input="actualizarError('password')"/>
          <p class="error-mess" v-if="errorObj.password.length > 0">{{ errorObj.password[0] }}</p>
          <i class="fa-regular iconEye" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'" @click="showPassword = !showPassword" ></i>
        </div>
        <p v-if="registrationError" class="error-mess2">{{ registrationError }}   <router-link to="/restablecer"> pulse aqui. </router-link> </p>


        <div class="botonFormulario">
          <button type="submit" class="btn">Crear cuenta</button>
        </div>

        <p class="avisoLegal"> Si creas una cuenta, aceptas nuestros
          <span @click="toggleLegal" style="cursor: pointer; color: blue;">Términos y condiciones, Política de Privacidad y Cookies.</span>
        </p>
      </form>
      <Legal v-if="mostrarLegal" @cerrar="mostrarLegal = false" />

  </div>
</template>


<script>
import { mapState } from "vuex";
import  {auth} from "../firebase";
import Legal from "./legal.vue";
export default {
    name: "Registro",
    data() {
        return {
            userData: {
                name: "",
                email: "",
                password: "",
            },
            showPassword: false,
            errorObj: {
                email: [],
                password: [],
                name: [],
            },
            errorMessage: "",
            mostrarLegal: false, // Asegúrate de que está aquí y es false inicialmente

          };
    },
    computed: {
        ...mapState({
            registrationError: (state) => state.registrationError,
        }),
    },
    created() {
        this.getUser();
    },
    methods: {
      toggleLegal() {
        this.mostrarLegal = !this.mostrarLegal; // Cambia el estado de mostrarLegal
    },
        getUser() {
            auth.onAuthStateChanged((user) => {
                if (user) {
                    this.$router.push("/home");
                }
                else {
                    return null;
                }
            });
        },
 
        async iniciarSesionConGoogle() {
            try {
                await this.$store.dispatch("iniciarSesionConGoogle");
            }
            catch (error) {
                console.error(error.message);
            }
        },
        showErrorMessages() {
            let hasErrors = false;
            if (this.userData.name.length === 0) {
                this.errorObj.name.push("Obligatorio.");
                hasErrors = true;
            }
            if (this.userData.name.length > 20) {
                this.errorObj.name.push("Máximo 20 letras.");
                hasErrors = true;
            }
            if (this.userData.email.length === 0) {
                this.errorObj.email.push("Obligatorio.");
                hasErrors = true;
            }
            if (this.userData.email.length > 0) {
                const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailPattern.test(this.userData.email)) {
                    this.errorObj.email.push("No válido.");
                    hasErrors = true;
                }
                else {
                    hasErrors = false;
                }
            }
            if (this.userData.password.length === 0) {
                this.errorObj.password.push("Obligatoria.");
                hasErrors = true;
            }
            if (this.userData.password.length > 0 && this.userData.password.length < 6) {
                this.errorObj.password.push("Mínimo 6 letras.");
                hasErrors = true;
            }
            if (this.userData.password.length > 50) {
                this.errorObj.password.push("Máximo 50 letras.");
                hasErrors = true;
            }
            return !hasErrors;
        },
        resetData() {
            this.userData.name = this.userData.email =
                this.userData.password = "";
        },
        resetCheckErr: function () {
            this.errorObj.email = [];
            this.errorObj.password = [];
            this.errorObj.name = [];
        },
        actualizarError(campo) {
            this.errorObj[campo] = [];
        },
        doRegister() {
            if (this.showErrorMessages()) {
                try {
                    this.$store.dispatch("doRegister", {
                        name: this.userData.name,
                        email: this.userData.email,
                        password: this.userData.password
                    });
                }
                catch (error) {
                    if (error.code === "auth/email-already-in-use") {
                        this.errorMessage = "La dirección de correo electrónico ya está en uso por otra cuenta.";
                    }
                    else {
                        this.errorMessage = "Ocurrió un error durante el registro.";
                        console.log("Error durante el registro:", error);
                    }
                    console.log(error);
                }
            }
            else {
                console.log("no se ha podido registrar al usuario");
            }
        },
    },
    components: { Legal }
}
</script>

<style scoped>

.error-mess {
font-size: 16px;
  position: relative;
  color: #FF6961;
  margin: 0;
  text-align: start;
  padding: 0;
}
.error-mess2 {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: center;
    padding: 0;
    margin-bottom: 18px;
    width: 100%;
}
.imagen{
  display: flex;
justify-content: center;
align-items: center;
}

.container {
padding: 6px;
height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
}

.iniciarsesion{
  font-size: 26px;
    margin-bottom: 20px;
    font-weight: bold;
}
.googleinitdiv{
  display: flex;
    align-items: center;
    padding: 10px;
    background: #0097B2;
    width: 90%;
    border-radius: 12px;
    justify-content: center;

}
.Appleinitdiv{
  width: 90%;
}
.avisoLegal{
  font-size: 16px;
    color: black;
    text-align: center;
}
.registroFormImput{
  border: none;
    padding-left: 12px;
    width: 100%;

}

.login-divider {
  display: flex;
    align-items: center;
    text-align: center;
    width: 90%;
}

.line {
  flex-grow: 1;
  height: 1px; /* Ajusta el grosor de la línea */
  background-color: #000; /* Ajusta el color de la línea */
}

.iconEye{
    color: black;
    font-size: 22px;
    margin-right: 5px;
}
.registroForm{
    display: flex;
    align-items: center;
    background: none;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 8px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
}
.or-circle {
 
  line-height: 40px; /* Asegura que la 'O' esté centrada verticalmente */
  background-color: #fff; /* Ajusta el color de fondo del círculo */
  color: #000; /* Ajusta el color de la 'O' */
  margin: 0 10px; /* Ajusta la separación entre la línea y el círculo */
  display: inline-block;
}

.botonFormulario{
  display: flex;
    align-items: center;
    background: none;
    background-color: var(--success-green);
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 10px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
    justify-content: center;

}
.legal-overlay {
  position: fixed; /* Posicionamiento fijo respecto a la ventana del navegador */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Fondo semitransparente */
  display: flex;
  justify-content: center; /* Centrado horizontal */
  align-items: center; /* Centrado vertical */
  z-index: 1000; /* Asegúrate de que el overlay esté por encima de otros elementos */
}

.legal-content {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px; /* Ajusta el ancho máximo del contenido legal según tus necesidades */
  width: 90%; /* Ajusta el ancho según tus necesidades */
  z-index: 1001; /* Asegúrate de que el contenido esté por encima del fondo semitransparente */
}

.googleinit{
  display: flex;
    border: none;
    align-items: center;
    background: none;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 8px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
    justify-content: center;

}

.google{
  font-size: 20px;
    margin: 0px;
    padding-left: 8px;
    font-weight: 500;
    color: black;

}
.iconGoogle{
  width: 25px;
    height: 25px;
}
.img {
  max-width: 100%;
max-height: 100%;
}
.crearcuenta{
    font-size: 34px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}

.container form {
  position: static;
background-color: white;
  border-radius: .5rem;
  animation: fadeUp .4s linear;
  height: auto;

}
.iconApple{
  color: black;
  font-size: 30px;
}


.container form .form-control {

  font-size: 20px;
  color: #130f40;

}

.btn {
  color: white;
    font-size: 22px;
    padding: 0;

}


.container form p a {
  color: #0097B2;
}

.container form .form-group {
display: flex;
    border: none;
    align-items: center;
    background: none;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 8px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
    justify-content: center;
}

.container form .error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
}

@media (max-width: 768px) {

.crearcuenta{
font-size: 36px;
  padding: 10px;
  text-align: center;
}
  .container{
    height: auto;
    padding-top: 22px;
    padding-bottom: 22px;
  }
  .container form {
      position: static;

      margin-top: 0px;
         padding: 1rem;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  margin-top: 18px;
  }
}

@media (max-width: 576px) {
.crearcuenta{
font-size: 34px;
  padding: 10px;
  text-align: center;
}
.container {
  height: auto;
    padding-top: 8px;
    padding-bottom: 22px;

}

.container form {
  position: static;
  padding-top: 0px;
  height: auto;
  margin-top: 18px;
}



.container form .form-control {
  font-size: 20px;
}

.container form label {
  font-size: 1.5rem;
}

.container form span {
  font-size: 16px;
}


.container .form-group .error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
}

.form-group input {
  padding-right: 30px; /* Ajusta el espaciado para dejar lugar al icono */
}

.form-group i {
  position: absolute;
  top: 50%;
  right: 10px;
  font-size: 22px;
  transform: translateY(-50%);
  color: black; /* Color del icono de check */
}
}

@media (min-width: 992px) {
.container {
  height: auto;
  max-width: 585px;
  justify-content: space-around;
  margin-top: 20px;
}

}
</style>
