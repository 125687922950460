<template>
    <vue-basic-alert :duration="300" :closeIn="2000" ref="alert" />

    <div class="containerPrincipal">
        <div v-if="Negocios.length === 0" class="container">
            <img class="imagen-centrada" src="../assets/images/comprar.png" alt="Logo" />
        </div>

        <div v-else class="negocios-container">
            <div class="negocio" v-for="negocio in Negocios" :key="negocio.id_negocio" @click="seleccionarNegocio(negocio.id_negocio, negocio.nombre)">
                <h2 class="nombreNegocio">{{ negocio.nombre }}</h2>
                <div v-if="loading">Cargando...</div>
                <div v-else>
                <img :src="negocio.staticMapUrl" alt="Mapa Estático" class="mapa-estatico">
                </div>
                <h5 class="direccion">{{ negocio.direccion }}</h5>
            </div>
        </div>
      
    </div>
        <i class="fas fa-plus-circle icono-añadir-negocio" @click="toggleFormulario"></i>

                <!-- ///////////////////////////MOSTRAR FORMULARIO ////////////////////////// -->
                <div class="quieckview" v-if="mostrarFormulario">
            <form class="formularioAñadirNegocio" @submit.prevent="irAFacturacion" autocomplete="off">
                <h2 class="tituloAñadir">Añadir negocio</h2>
                <p class="avisoSuscripcion">
                    Dar de alta un negocio tiene un costo anual de {{ precio_software.toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}.
                </p>
                <div class="form-group">
                    <input class="formcontrol1" type="text" v-model="NuevoNegocios.nombre" placeholder="Nombre del Negocio" maxlength="25" required />
                </div>
                <div class="form-group">
                    <input class="formcontrol1" id="autocomplete" ref="autocompleteInput" type="text" v-model="NuevoNegocios.direccion" placeholder="Dirección completa" @focus="initAutocomplete" maxlength="500" required />
                </div>
                <div class="form-group">
                    <button class="btn-cancelar" type="button" @click="cerrarFormulario">Cancelar</button>
                    <!-- Aquí se mantiene la lógica de desactivación pero se agrega el tipo "submit" al botón para que el formulario intente enviar y aplique la validación HTML -->
                    <button class="btn-añadir" type="submit" :disabled="NuevoNegocios.nombre === '' || NuevoNegocios.direccion === ''">Continuar</button>
                </div>
            </form>
        </div>

<!-- /////////////////////////////MOSTRAR AÑADIR DATOS FACTURACIÓN////////////////////// -->
        <div class="quieckview" v-if="showDatosFacturacion">
            <form class="formularioAñadirNegocio" @submit.prevent="dniInvalido ? null : irAPago()" autocomplete="off">
                <h2 class="tituloAñadir">Datos de facturación</h2>
                <div class="form-group">
                    <input class="formcontrol1" type="text" placeholder="DNI / CIF" id="dni" v-model="Cliente.dni" maxlength="25" required/>
                </div>

                <div class="form-group">
                    <input class="formcontrol1" type="text" placeholder="Nombre y Apellidos" id="nombre" v-model="Cliente.nombre" maxlength="50" required />
                </div>

                <div class="form-group">
                    <input class="formcontrol1" type="text" v-model="Cliente.direccion" placeholder="Domicilio fiscal completo" @focus="initAutocomplete2" ref="autocompleteInput2" maxlength="100" required>
                </div>

                <div class="form-group">
                    <button class="btn-cancelar" type="button" @click="cerrarFormulario">Cancelar</button>
                    <button class="btn-añadir" type="submit" :disabled=" Cliente.nombre === '' || Cliente.dni === '' || Cliente.direccion  === '' " >Continuar</button>
                </div>
            </form>
        </div>

<!-- /////////////////////////////MOSTRAR PAGO////////////////////// -->
        <div class="quieckview" v-if="pago">
            <div class="payment-container">
                <form style="width: 100%;"  @submit.prevent="confirmPayment">
                    <h2 class="tituloAñadir">Detalles de pago</h2>
                    <div class="form-group">
                        <div class="form-group">
                            <input class="formcontrol1" type="text" id="cardholder-name" v-model="paymentDetails.cardholderName" required placeholder="Nombre en la tarjeta">
                        </div>
                            <input class="formcontrol1" type="text" id="card-number" v-model="paymentDetails.cardNumber" required placeholder="Número de tarjeta">
                    </div>
                    <div class="form-group expiry-cvv" style="justify-content: space-between;">
                        <div>
                        <select class="formcontrol1" id="expiry-month" v-model="paymentDetails.expiryMonth" required>
                            <option value="" disabled selected>mes</option>
                        <option  v-for="month in 12" :key="month" :value="pad(month)">{{ pad(month) }}</option>
                        </select>
                    </div>
                    <div>
                        <select class="formcontrol1" id="expiry-year" v-model="paymentDetails.expiryYear" required>
                        <option value="" disabled selected>año</option>
                        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                        </select>
                    </div>
                   
                    </div>
                    <div>
                        <input class="formcontrol1" type="text" id="cvv" v-model="paymentDetails.cvv" required placeholder="123">
                    </div>
                    <div class="form-group">
                        <button type="button" @click="cancelPayment" class="btn-cancelar">Cancelar</button>
                        <button type="submit" class="btn-añadir">Confirmar {{ calculoPrecioTotal().toLocaleString("es-ES", { style: "currency", currency: "EUR" }) }}</button>
                    </div>
                </form>
            </div>
        </div>

<!-- /////////////////////////////MOSTRAR PAGO CONFIRMADO////////////////////// -->
        <div class="quieckview" v-if="pagoConfirmado">
            <div class="containerPagoConfirmado">
                <h2 class="suscripcionActivada"> ¡Suscripción activada! </h2>
                <div style="width: 100%;">
                    <div class="gracias-animation">
                        <span>GRACIAS</span>
                    </div>
                </div>
                <h2 class="comprarZatec"> ¿Deseas comprar ahora los dispositivos Zatec? </h2>
                <div class="form-group">
                    <button  class="btn-cancelar" @click="cancelarCompraZatec">No, luego.</button>
                    <button class="btn-añadir" @click="comprarZatec">Si, vamos a comprar.</button>
                </div>
               
            </div> 
        </div>
</template>

<script>
  
import { auth } from "../firebase";
import axios from "axios";
import confetti from 'canvas-confetti';
import VueBasicAlert from 'vue-basic-alert';


export default{
    data() {
        const currentYear = new Date().getFullYear();
        return {
            
            loading: true,
            pagoConfirmado: false,
            staticMapUrl: '',
            pago: false,
            mostrarFormulario: false,
            nameUser: '',
            agreedToContract: false,
            showContractQuickView: false,
            showSuccessMessage: false,
            userUid: null,
            NuevoNegocios: {
                id_negocio: "",
                nombre: "",
                clave_chip: "",
                direccion : "",
                lat: "",
                lon: ""
            },
            paymentDetails: {
                cardNumber: '',
                cardholderName: '',
                expiryMonth: '',
                expiryYear: '',
                cvv: '',
          
            },
            email: "",
            idNegocio: null,
            years: Array.from({ length: 20 }, (_, i) => currentYear + i),
            precio_software: 40,
            Negocios: [],
            Suscripcion: [],
            Cliente: {
                dni: '',
                nombre: '',
                direccion: '',
            },
            dniInvalido: false,
            showDatosFacturacion: false,
            idNegocioInsertado: null,
            autocomplete1: null, 
            autocomplete2: null
        }
    },
  
    created() {
        this.initializeUser();
    },
    computed: {
        esBotonDisabled() {
            return !this.NuevoNegocios.nombre || !this.NuevoNegocios.direccion;
        },
    
        fechaHoy() {
            const hoy = new Date();
            const opciones = { year: 'numeric', month: 'long', day: 'numeric' };
            return hoy.toLocaleDateString('es-ES', opciones);
        },
        fechaUnAnoMasTarde() {
            const hoy = new Date();
            hoy.setFullYear(hoy.getFullYear() + 1); 
            const year = hoy.getFullYear();
            const month = ('0' + (hoy.getMonth() + 1)).slice(-2);
            const day = ('0' + hoy.getDate()).slice(-2);

            return `${year}-${month}-${day}`;
        },
        fechaHoyMySQLFormat() {
            const hoy = new Date();
            const year = hoy.getFullYear();
            const month = ('0' + (hoy.getMonth() + 1)).slice(-2);
            const day = ('0' + hoy.getDate()).slice(-2);

            return `${year}-${month}-${day}`;
        }
    },
    mounted() {
        if (this.$route.query.success) {
            this.showSuccessMessage = true;
            this.$refs.alert.showAlert('success', '', 'Negocio eliminado.')

            // Opcional: Ocultar el mensaje después de un tiempo
            setTimeout(() => {
                this.showSuccessMessage = false;
            }, 3000); // Ajusta el tiempo según sea necesario
        }
    },
 
    methods: {

        comprarZatec(){
            this.lanzarConfeti();
            this.irATienda();
        },
  
        irATienda() {
        this.$router.push('/comprar');
    },
        initializeUser() {
            auth.onAuthStateChanged(user => {
                if (user) {
                    this.userUid = user.uid;
                    this.nameUser = user.displayName;
                    this.email = user.email;
                    this.obtenerDatosNegocio(user.uid);
                } else {
                 this.$router.push("/login");
                }
            });
        },

        toggleFormulario() {
            this.mostrarFormulario = !this.mostrarFormulario;
        },

        lanzarConfeti() {
            confetti({
                particleCount: 100,
                spread: 70,
                origin: { y: 0.6 }
            });
        },

        pad(number) {
            return number.toString().padStart(2, '0');
        },

        cancelarCompraZatec() {
            this.pago = false,
            this.mostrarFormulario= false,
            this.pagoConfirmado = false;
            this.NuevoNegocios.nombre = null,
            this.NuevoNegocios.direccion = null,
            this.Cliente.nombre = null,
            this.Cliente.dni = null,
            this.Cliente.direccion = null,
            this.agreedToContract = false,
            this.paymentDetails.cardNumber = null,
            this.paymentDetails.cardholderName = null,
            this.paymentDetails.cvv = null,
            this.paymentDetails.expiryMonth = null,
            this.paymentDetails.expiryYear = null
        },


        confirmPayment() {
            console.log('Payment confirmed with:', this.paymentDetails);
            this.añadirNuevoNegocio();
            this.pago = false,
            this.mostrarFormulario= false,
            this.pagoConfirmado = true;
            this.lanzarConfeti();
        },

        cancelPayment() {
            this.pago = false,
            this.mostrarFormulario= false,
            console.log('Payment cancelled');
        },
        cancelarContrato(){
            this.pago = false,
            this.mostrarFormulario= false
        },

        initAutocomplete() {
            const input = this.$refs.autocompleteInput;
            if (!input) return;
            this.autocomplete1 = new google.maps.places.Autocomplete(input, {
                types: ["address"],
                componentRestrictions: { country: "es" }
            });
            this.autocomplete1.addListener("place_changed", this.onPlaceChanged);
            console.log(this.onPlaceChanged)
        },

        initAutocomplete2() {
            const input = this.$refs.autocompleteInput2;
            if (!input) return;
            this.autocomplete2 = new google.maps.places.Autocomplete(input, {
                types: ["address"],
                componentRestrictions: { country: "es" }
            });
            this.autocomplete2.addListener("place_changed", this.onPlaceChanged2);
        },

        onPlaceChanged() {
            const place = this.autocomplete1.getPlace();
            if (place.geometry) {
                this.NuevoNegocios.direccion = place.formatted_address;
                this.NuevoNegocios.lat = place.geometry.location.lat();
                console.log(this.NuevoNegocios.lat)
                this.NuevoNegocios.lon = place.geometry.location.lng();
            }
        },

        onPlaceChanged2() {
            const place = this.autocomplete2.getPlace();
            if (place.geometry) {
                this.Cliente.direccion = place.formatted_address;
                console.log(this.Cliente.direccion)
            }
        },

        irAFacturacion(){
            this.mostrarFormulario = false;
            this.showDatosFacturacion = true;
            this.pago = false;

        },
        irContrato()
        {
            this.showContractQuickView = true;
            this.showDatosFacturacion = false;
            this.pago = false;
        },

        irAPago(){
            this.showContractQuickView = false;
            this.showDatosFacturacion = false;
            this.pago = true;
        },

        seleccionarNegocio(idNegocio, nombreNegocio) {
            
            localStorage.setItem('idNegocio', idNegocio);
            localStorage.setItem('nombrenegocio', nombreNegocio);

            this.$store.commit('setidNegocio', idNegocio);
            this.$store.commit('setNombreNegocio', nombreNegocio);
            this.$router.push({ name: 'pdf' });
            this.$nextTick(() => {
                window.scrollTo(0, 0); // Esto mueve el scroll al inicio de la página
            });
        },

        async obtenerDatosNegocio(cliente_uid) {
            try {
                const response = await axios.post("/datosNegocio", { cliente_uid });
                this.Negocios = response.data.map(negocio => {
                    return { ...negocio, staticMapUrl: this.generateStaticMapUrl(negocio.lat, negocio.lon)};
                });
                this.loading = false;
            } catch (error) {
                console.error("Error al obtener los negocios:", error);
                this.loading = false;
            }
        },
        async añadirNuevoNegocio() {
            try {
                const response = await axios.post("/insertarNegocio", {
                    cliente_uid: this.userUid,
                    clave_chip: this.userUid.slice(-4),
                    nombre: this.NuevoNegocios.nombre,
                    direccion: this.NuevoNegocios.direccion,
                    lat: this.NuevoNegocios.lat,
                    lon: this.NuevoNegocios.lon
                });
                console.log("PASO1",response.data.id)
                this.añadirDatosFacturacion(response.data.id);
                this.obtenerDatosNegocio(this.userUid);
            }catch (error) {
                console.error('Error al insertar negocio:', error);
            }
        },

        async añadirDatosFacturacion(id_negocio) {
            console.log("añadir datos facturacion", id_negocio)
                try {
                    const response = await axios.post("/agregarCliente", {
                        id_negocio: id_negocio,
                        cliente_uid: this.userUid,
                        dni: this.Cliente.dni,
                        nombre: this.Cliente.nombre,
                        direccion: this.Cliente.direccion,
                        email: this.email
                });
                this.añadirDatosSuscripcion(id_negocio)
                } catch (error) {
                console.error("Error al agregar el cliente:", error);
                }
        },

        async añadirDatosSuscripcion(id_negocio) {
            console.log("añadir datos suscripcion", id_negocio)

                try {
                    const response = await axios.post("/insertarSuscripcion", {
                        id_negocio: id_negocio,
                        cliente_uid: this.userUid,
                        precio_base : this.precio_software,
                        fecha_inicio: this.fechaHoyMySQLFormat,
                        fecha_final: this.fechaUnAnoMasTarde,
                        estado: 1,
                });
                this.añadirDatosGenerarFactura(id_negocio)
                } catch (error) {
                console.error("Error al agregar el cliente:", error);
                }
        },

        async añadirDatosGenerarFactura(id_negocio) {
            console.log("añadir datos generrarfactura", id_negocio)
                try {
                    const response = await axios.post("/insertarFacturaSoftware", {
                        fecha_emision: this.fechaHoyMySQLFormat,
                        dni: this.Cliente.dni,
                        nombre: this.Cliente.nombre,
                        direccion: this.Cliente.direccion,
                        cliente_uid: this.userUid,
                        id_negocio: id_negocio,
                        iva: 21,
                        total: this.precio_software,
                        email: this.email
                });
                console.log(response.data)
                } catch (error) {
                console.error("Error al agregar el cliente:", error);
                }
        },

        generateStaticMapUrl(lat, lon) {
            const baseUrl = 'https://maps.googleapis.com/maps/api/staticmap';
            const params = `?center=${lat},${lon}&zoom=19&size=350x300&key=AIzaSyCdzApUaTG00U_6Xx7IYNYPsCwQ4MdUpTc`;
            const url  = baseUrl + params;
            return url
        },

        calculoPrecioTotal() {
            const precioBasePorNegocio = 40; // Precio por negocio
            return precioBasePorNegocio;
        },


        cerrarFormulario() {
            this.mostrarFormulario = false;
            this.showDatosFacturacion = false;
            this.NuevoNegocios.nombre = null;
            this.NuevoNegocios.direccion = null
        },

        toggleFormulario() {
            this.mostrarFormulario = !this.mostrarFormulario;
        },    
    },
    components: {
        VueBasicAlert
    },
}
</script>
  
<style scoped>

.contract-link {
    cursor: pointer;
    text-decoration: underline;
}

.quickview-container {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}
.containerPrincipal{
    max-width: 96%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;
}

.quickview-content {
    background-color: white;
    padding: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    height: 80%;
    width: 96%;
    border-radius: 12px;
    text-align: justify;
    overflow-y: auto;
    margin-bottom: 110px;
}
.section {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    flex-wrap: wrap;
}

.container {
  display: flex; /* Usa flexbox para el contenedor */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 100vh; /* Establece la altura del contenedor al 100% de la altura de la ventana del navegador */
}

.imagen-centrada {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}


.negocios-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 12px;
    padding-top: 12px;
    padding: 4px;

}

.negocio {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 12px;
    padding: 12px;
}


.mapa-estatico {
  width: 100%; /* Asegura que la imagen se ajuste al ancho del contenedor .negocio */
  height: auto; /* Mantiene la proporción de la imagen */
}


.SiComprarZatec{
    padding: 10px 0px;
    background-color: #0097b2;
    color: white;
    width: 50%;
    border: none;
    border-radius: 12px;
    margin: 12px;
    cursor: pointer;
    font-size: 20px;
}
.NoComprarZatec{
    padding: 10px 0px;
    background-color: #0097b2;
    color: white;
    width: 50%;
    border: none;
    border-radius: 12px;
    margin: 12px;
    cursor: pointer;
    font-size: 20px;

}
.no-negocios {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 100%;
    font-size: 22px;
    padding-left: 28px;
    padding-right: 28px;
}
.contrato{
    margin-bottom: 35px;
}
.icono-añadir-negocio {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 50px;
    color: var(--success);
    cursor: pointer;
    z-index: 900;
}
.payment-container {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    width: 93%;
    display: flex;
    max-width: 400px;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.comprarZatec{
    font-size: 24px;
    text-align: center;
    margin-top: 2rem;
}

h2 {
  text-align: start;
  margin-bottom: 2rem;
  font-size: 24px;
}
.pagoConfirmadoTitulo{
    margin-bottom: 20px;
}

.form-group {
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: .5rem;
}

.acceptContrato{
    box-shadow: 0px -2px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    position: fixed;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    color: #fff;
    display: flex;
    height: 127px;
    width: 96%;
    background-color: #0097b2;
    padding: 12px;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: center;
}
.expiry-cvv {
  display: flex;
  justify-content: space-between;
}

.imgNegocios{
    width: 100%;
    height: 100%;
}
.expiry-cvv div {
  width: 30%;
}
.formularioAñadirNegocio{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 12px;
    width: 93%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 24px;
}
.checkbox{
    width: 20px;
    height: 20px;
    margin-right: 8px;
}
.obligado{
    color: #0097B2;
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;
}

.formcontrol1{
    display: block;
    width: 100%;
    color: black;
    background-color: #f9fafc;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
    padding: 1rem;
    font-size: 18px;
    border: none;
    margin-bottom: 1rem;
}

.errorDNI{
    color: var(--error-red);
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 0;
    margin-top: 12px;
    margin-left: 20px;
}

.form-group {

    margin-bottom: 0rem;
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.form-group0{
    margin-bottom: 1rem;
    width: 100%;

}
.suscripcionActivada{
    color: var(--success);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 12px 
px
;
    text-align: center;
}

.pago{
    color: var(--success);
    font-size: 70px;
    margin-top: 15px;
}

.checkbox-container {
    font-size: 18px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 96%;
    justify-content: space-between;
    align-items: stretch;
}

.containerPagoConfirmado{
    background-color: white;
    width: 93%;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.avisoSuscripcion{
    text-align: center;
    font-size: 18px;
    font-weight: 500;
}
.direccion{
    margin-top: 12px;
    color: rgb(86, 87, 97);
    font-size: 18px;
    margin-bottom: 0rem;
    text-align: start;
}

.btn-añadir {
    padding: 9px 9px;
    background-color: var(--success);
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 20px;
    margin-top: 6px;
}


.btn-cancelar {
    padding: 9px 9px;
    background-color: white;
    color: black;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    font-size: 20px;
    margin-top: 6px;
}
.formularioAñadirNegocio{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 12px;
    width: 93%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    justify-content: center;
}

.btn-añadir:disabled {
    background-color: #cccccc; /* Color de fondo más claro o gris para indicar que está desactivado */
    color: #666666; /* Color de texto más claro */
    cursor: not-allowed; /* Cambiar el cursor para indicar que no está permitido hacer clic */
    font-size: 20px;
    padding: 9px 9px;
}


.tituloAñadir{
    color: var(--success);
    font-size: 28px;
    font-weight: 500;
    margin-bottom: 12px;
    text-align: center;
}
.mapa-estatico{
    border-radius: 12px;
    margin-right: auto;
    width: 100%;
    height: 100%;
    margin-left: auto;
}
.nombreNegocio{
    font-size: 28px;
    margin-bottom: 12px;
    color: black;
    font-weight: 500;
}
.quieckview{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background-color: rgb(0 0 0 / 46%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.form-group0{
    cursor: pointer;
    padding: 16px;
    border-radius: 12px;
    margin-top: 15px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    margin-bottom: 20px;
}
@import url("https://fonts.googleapis.com/css?family=Luckiest+Guy");

.thank-container {
    padding: 2rem 9%;
    background: #fff;
    height: 90vh;
}


.thank-container h1 {
    width: 100%;
    height: 100px;
    margin: auto;
    display: block;
    text-align: center;
    margin-top: 50px;
    font-size: 42px;
}


.thank-letter {
    position: relative;
    top: 50px;
    font-size: 20px;
    width: 40vw;
    margin: 0 auto;
    text-align: center;
}

.thank-letter a {
    margin-top: 20px;
}


.gracias-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    }
    .gracias-animation span {
        font-size: 52px;
    font-weight: 500;
    color: black;
    opacity: 0;
    transform: scale(0.8);
    animation: graciasAnim-8556bba6 2s ease-in-out infinite;



}

@keyframes graciasAnim {
  0%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@media (min-width: 1024px) {
    .icono-añadir-negocio {
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 70px;
    color: var(--success);
    cursor: pointer;
    z-index: 900;
}
    .imagen-centrada {
  max-width: 60%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}

    .containerPrincipal{
    max-width: 85%;
    margin-right: auto;
    margin-left: auto;
    margin-bottom: 20px;

}
    .negocios-container {
        display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 20px;
    padding: 10px;

}

.negocio {
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
    border-radius: 12px;
    padding: 12px;
}



.mapa-estatico {
  width: 100%; /* Asegura que la imagen se ajuste al ancho del contenedor .negocio */
  height: auto; /* Mantiene la proporción de la imagen */
}


.imgNegocios{
    width: 100%;
    height: 100%;
}
    .form-group{
        margin-bottom: 0rem;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    padding: 0px;
    }

    .tituloAñadir{
        color: var(--success);
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 12px;
    text-align: center;

    }
    .containerPagoConfirmado{
        background-color: white;
    max-width: 40%;
    border-radius: 12px;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    }

    .quickview-content {
    background-color: white;
    padding: 20px;
    /* border-radius: 5px; */
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    height: 80%;
    width: 75%;
    border-radius: 12px;
    text-align: justify;
    overflow-y: auto;
    margin-bottom: 110px;
}
.checkbox-container{
    font-size: 20px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    width: 96%;
    justify-content: flex-start;
    align-items: stretch;
    
}
.checkbox{
    width: 25px;
    height: 25px;
    margin-right: 8px;
}
.acceptContrato{
    box-shadow: 0px -2px 5px rgba(0,0,0,0.1);
    font-size: 16px;
    position: fixed;
    bottom: 6px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 12px;
    color: #fff;
    display: flex;
    height: 127px;
    width: 75%;
    background-color: #0097b2;
    padding: 12px;
    flex-wrap: wrap;
    align-content: flex-end;
    justify-content: center;
}
    .section {
        display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: auto;
    padding: 20px;
    flex-wrap: nowrap;
}
.formularioAñadirNegocio{
    display: flex;
    flex-wrap: wrap;
    background-color: white;
    border-radius: 12px;
    max-width: 570px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    padding: 20px;
    justify-content: center;
}
.payment-container {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    max-width: 40%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
    .gracias-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 160px;
    }

    .gracias-animation span {
        font-size: 52px;
    font-weight: 500;
    color: black;
    opacity: 0;
    transform: scale(0.8);
    animation: graciasAnim-8556bba6 2s ease-in-out infinite;



}

@keyframes graciasAnim {
  0%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}
    .thank-container {
        padding: 0px;
    }

    .thank-container h1 {
        margin-top: 20px;
    }

    .thank-container h1 p span {
        font-size: 70px;
    }

    .thank-letter {
        font-size: 20px;
        width: 50vw;
        top: 160px;
    }
}

@media (max-width: 576px) {

.imgNegocios{
    width: 100%;
    height: 100%;
}
  .gracias-animation {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 95px;
  }

  .gracias-animation span {
        font-size: 52px;
    font-weight: 500;
    color: black;
    opacity: 0;
    transform: scale(0.8);
    animation: graciasAnim-8556bba6 2s ease-in-out infinite;



}

@keyframes graciasAnim {
  0%, 100% {
    opacity: 0;
    transform: scale(0.8);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}
}




</style>