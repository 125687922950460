import { createWebHistory, createRouter } from "vue-router";
import pdf from "@/pages/pdf.vue";
import Login from '@/pages/Login.vue';
import redes from "@/pages/redes.vue";
import Restablecer from '@/pages/Restablecer.vue';
import cookies from "@/pages/cookies.vue";
import datos from "@/pages/datos.vue";
import negocios from "@/pages/negocios.vue";
import comprar from "@/pages/tienda.vue";
import registro from "@/pages/registro.vue";
import Cart from "@/pages/Cart.vue";
import legal from "@/pages/legal.vue";
import perfil from "@/pages/perfil.vue";
const routes = [

  {
    path: "/legal",
    name: "legal",
    component: legal,
  },

  {
    path: "/perfil",
    name: "perfil",
    component: perfil,
  },
  {
    path: "/registro",
    name: "registro",
    component: registro,
  },

  {
    path: "/pedido",
    name: "pedido",
    component: Cart,
  },

  {
    path: "/cookies",
    name: "cookies",
    component: cookies,
  },

  {
    path: "/datos",
    name: "datos",
    component: datos,
  },
  {
    path: "/comprar",
    name: "comprar",
    component: comprar,

  },

  {
    path: "/negocios",
    name: "negocios",
    component: negocios,
  },

  {
    path: "/pdf",
    name: "pdf",
    component: pdf,

  },

  {
    path: "/redes/:id_negocio",
    name: "redes",
    component: redes, 
   
  },

  {
    path: "/redes",
    name: "redes",
    component: redes, 
   
  },

  {
    path: "/restablecer",
    name: "restablecer",
    component: Restablecer, 
   
  },

  {
    path: "/login",
    name: "Login",
    component: Login,
  },

  
  
  {
    path: '/:pathMatch(.*)*',
    component: Login,
    // https://stackoverflow.com/questions/68504803/how-can-i-make-a-catch-all-other-route-in-vue-router-also-catch-the-url-when-p
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;