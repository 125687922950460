<template>
      <div class="container">
        <h3 class="iniciarsesion">Inicia sesión</h3>
        <button class="googleinit" @click="iniciarSesionConGoogle">
            <img class="iconGoogle" :src="require('@/assets/images/google.png')">
            <p class="google">Continuar con Google</p>
          </button>
          
          <button class="googleinit" @click="iniciarSesionConApple">
            <i class="fa-brands fa-apple iconApple"></i>
            <p class="google">Continuar con Apple</p>
          </button>    
        <div class="login-divider">
          <div class="line"></div>
          <div class="or-circle">o</div>
          <div class="line"></div>
        </div>
        <form @submit.prevent="doLogin" novalidate autocomplete="off">

        <div class="form-group">
          <input type="email" placeholder="Correo electrónico" class="form-control2" v-model="userData.email" @input="actualizarError('email')"/>
          <p class="error-mess" v-if="errorObj.email.length > 0">{{ errorObj.email[0] }}</p>

        </div>

        <div class="form-group">
          <input :type="showPassword ? 'text' : 'password'" placeholder="Contraseña" class="form-control2" v-model="userData.password" @input="actualizarError('password')"/>
          <p class="error-mess" v-if="errorObj.password.length > 0">{{ errorObj.password[0] }}</p>
          <i class="fa-regular iconEye" :class="showPassword ? 'fa-eye-slash' : 'fa-eye'" @click="showPassword = !showPassword" ></i>

        </div>

        <div class="text-center d-flex justify-center align-center">
          <router-link class="formPass" to="/restablecer">¿Contraseña olvidada?</router-link>
        </div>
        
        <p v-if="registrationError" class="error-mess">{{ registrationError }}   <router-link to="/restablecer"> pulse aqui. </router-link> </p>

        <button  type="submit" class="btn"> Inicia sesión </button>
        <p class="notienes">¿Nuevo en mizatec? <router-link style="color: #2a79c2;" to="/registro">Crear cuenta</router-link></p>
      </form>
    </div>
</template>


<script>
import { mapState } from "vuex";
import  {auth} from "../firebase";

export default {
  name: "Login",
  data() {
      return {
        valueDeterminate: 100,
          userData: {
              email: "",
              password: "",
          },
          showPassword: false,
          errorObj: {
          email: [],
          password: [],
          },
          errorMessage: "", 

      };
  },
  computed: {
  ...mapState({registrationError: (state) => state.registrationError}),
},

created() {
    this.getUser();
  },
  methods: {

    getUser() {
      auth.onAuthStateChanged((user) => {
        if (user) {

          this.$router.push("/negocios");
        } else {
          return null;
        }
      });
    },


    async iniciarSesionConGoogle() {
            try {
            await this.$store.dispatch("iniciarSesionConGoogle");
            } catch (error) {
            console.error(error.message);
            }
        },


    async iniciarSesionConApple() {
            try {
            await this.$store.dispatch("iniciarSesionConApple");
            } catch (error) {
            console.error(error.message);
            }
        },

  showErrorMessages() {
      let hasErrors = false;

      if (this.userData.email.length === 0) {
          this.errorObj.email.push("Obligatorio.");
          hasErrors = true;
      }
      if(this.userData.email.length > 0) {
              const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailPattern.test(this.userData.email)) {
                  this.errorObj.email.push("No válido.");
                  hasErrors = true;
              } else {
                  hasErrors = false;
              }
      }
      
      if (this.userData.password.length === 0) {
          this.errorObj.password.push("Obligatoria.");
          hasErrors = true;
      }
      if (this.userData.password.length > 0 && this.userData.password.length < 6 ) {
          this.errorObj.password.push("Mínimo 6 letras.");
          hasErrors = true;
      }
      if (this.userData.password.length > 50) {
          this.errorObj.password.push("Máximo 50 caracteres.");
          hasErrors = true;
      }
      return !hasErrors;
  },
  resetData()
      {
          this.userData.name = this.userData.email = 
          this.userData.password = "";
  },
  resetCheckErr: function () {
          this.errorObj.email= [];
          this.errorObj.password = [];
          this.errorObj.name = [];
  },
  actualizarError(campo) {
      this.errorObj[campo] = [];
  },
  async doLogin() {
  if (this.showErrorMessages()) {
    try {
      await this.$store.dispatch("doLogin", {
        email: this.userData.email,
        password: this.userData.password,
      });

      // Obtener el usuario actual
      const user = this.$store.state.user; // Asume que el usuario se guarda en el estado de Vuex
    } catch (error) {
      // Manejo de errores de inicio de sesión
      // ...
    }
  }
},


  },
}
</script>

<style scoped>

.formPass{
  margin-top: -5px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 18px;
    margin-left: 4px;
}

.imagenweb{
  max-height:500px;

}

.login-divider {
  display: flex;
    align-items: center;
    text-align: center;
    width: 90%;
}

.line {
  flex-grow: 1;
  height: 1px; /* Ajusta el grosor de la línea */
  background-color: #000; /* Ajusta el color de la línea */
}

.iconEye{
    color: black;
    font-size: 22px;
    margin-right: 5px;
}

.or-circle {
 
  line-height: 40px; /* Asegura que la 'O' esté centrada verticalmente */
  background-color: #fff; /* Ajusta el color de fondo del círculo */
  color: #000; /* Ajusta el color de la 'O' */
  margin: 0 10px; /* Ajusta la separación entre la línea y el círculo */
  display: inline-block;
}

.notienes{
    font-weight: 500;
    font-size: 16px;
}
.btn{
  display: flex;
    align-items: center;
    background: none;
    background-color: var(--success-green);
    border-radius: 12px;
    width: 100%;
    padding: 10px;
    color: white;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
    justify-content: center;
    font-size: 22px;

}
.error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: auto;
}
.imagen{
  display: flex;
justify-content: center;
align-items: center;
}

.form-control2{
  border: none;
    padding-left: 12px;
    width: 100%;
}

.container {
padding: 6px;
height: auto;
display: flex;
justify-content: center;
align-items: center;
flex-wrap: wrap;
}
/* .container {
  background-image: url("../assets/images/fondowebmovil.svg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 700px;
  width: 100%;
} */

.img {
  max-width: 100%;
max-height: 100%;
}
.iniciarsesion{
  font-size: 34px;
    padding: 10px;
    text-align: center;
    margin-bottom: 20px;
    width: 100%;
}
.googleinitdiv{
  display: flex;
    align-items: center;
    padding: 10px;
    background: #0097B2;
    width: 100%;
    border-radius: 12px;
    justify-content: center;

}

.googleinit{
  display: flex;
    border: none;
    align-items: center;
    background: none;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 8px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
    justify-content: center;

}
.iconGoogle{
  width: 25px;
    height: 25px;
}

.google{
  font-size: 20px;
    margin: 0px;
    padding-left: 8px;
    font-weight: 500;
    color: black;

}
.iconApple{
  color: black;
  font-size: 30px;
}

.prueba{
  background-color: white;
    border-radius: 12px;
    padding: 1.5rem;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
}
.has-text {
  border: 1px solid red; /* Puedes personalizar los estilos del borde aquí */
}


.register-container {
  padding: 2rem 9%;
}

.register-container .register-form-container {
  background: #fff;
  height: 90vh;

}

.container form {
  position: static;
    background-color: white;
    border-radius: 0.5rem;
    animation: fadeUp .4s linear;
    height: auto;
    min-width: 400px;
    margin-top: 6px;

}



.form-control {
  margin: 0.7rem 0;
  border-radius: 0.5rem;
  background: #f7f7f7;
  padding: 2rem 1.2rem;
  font-size: 18px;
  color: #130f40;
  text-transform: none;
  width: 100%;
  border: 1px solid #70bbb1;
}




.container form p {
    font-size: 18px;
    color: black;
    margin: 0;
    text-align: center;
}

.container form p a {
  color: #0097B2;
}

.container form p a:hover {
  color: #130f40;
  text-decoration: underline;
}

.form-group {
  display: flex;
    align-items: center;
    background: none;
    color: white;
    font-size: 20px;
    border-radius: 12px;
    width: auto;
    padding: 8px;
    border: none;
    margin-bottom: 18px;
    box-shadow: 0 0 4px 0 #828a8b;
}

 form .error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
}

@media (max-width: 768px) {


   .container{
    height: auto;
    padding-top: 8px;
    padding-bottom: 22px;
  }
  .container form {
    position: static;
    min-width: 400px;
    margin-top: 0px;
    padding: 1rem;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    margin-top: 6px;
  }
}

@media (max-width: 576px) {
  .container {
  height: auto;
    padding-top: 8px;
    padding-bottom: 22px;

}

.container form {
  position: static;
    height: auto;
    min-width: 380px;
    margin-top: 6px;
}


.form-control {
  padding: 1.5rem 1rem;
  font-size: 18px;
  border: 1px solid #70bbb1;
}

.container form label {
  font-size: 1.5rem;
}

.container form span {
  font-size: 16px;
}

.container form p {
  font-size: 18px;
    text-align: center;
    color: black;
    
}

.form-group .error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
  }


.form-group i {
    font-size: 22px;
    color: black;
}
}
@media (min-width: 992px) {
.container {
  height: auto;
  max-width: 585px;
  justify-content: space-around;
  margin-top: 20px;
}

}
</style>
