<template>
  <div class="container">
    <div style="display: flex;    flex-wrap: wrap;     justify-content: center;">
          <h3 class="iniciarsesion">Restablecer contraseña</h3>
        <form @submit.prevent="doReset" novalidate autocomplete="off">

        <div class="form-group">
          <input type="email" placeholder="Correo electrónico" class="form-control2" v-model="userData.email" @input="actualizarError('email')"/>
          <p class="error-mess" v-if="errorObj.email.length > 0">{{ errorObj.email[0] }}</p>
        </div>

        <p class="error-mess2" v-if="errorObj.noexiste.length > 0">{{ errorObj.noexiste }}</p>


        <button  type="submit" class="btn"> Restablecer contraseña </button>
      </form>
    </div>
   
</div>
</template>


<script>
 import  {auth} from "../firebase";
  import VueBasicAlert from 'vue-basic-alert';

  export default {
    name: "RestablecerContraseña",
    data() {
        return {
            userData: {
                email: "",
            },
          errorObj: {
          email: [],
          noexiste: []
          },
          errorMessage: "", 

        };
    },
  
    created() {
        this.getUser();
    },
  
    methods: {
  
      getUser() {
        auth.onAuthStateChanged((user) => {
          if (user) {
            this.$router.push("/datos");
          } else {
            return null;
          }
        });
      },
  

      showErrorMessages() {
      let hasErrors = false;

      if (this.userData.email.length === 0) {
          this.errorObj.email.push("Obligatorio.");
          hasErrors = true;
      }
      if(this.userData.email.length > 0) {
              const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
              if (!emailPattern.test(this.userData.email)) {
                  this.errorObj.email.push("No válido.");
                  hasErrors = true;
              } else {
                  hasErrors = false;
              }
      }
      return !hasErrors;
  },
      resetData()
        {
          this.userData.email = "";      
      },
  
      resetCheckErr: function () {
          this.errorObj.email= [];
  },
  
  actualizarError(campo) {
      this.errorObj[campo] = [];
  },
  
    async doReset(){
        if (this.showErrorMessages()) {
            try {
                await this.$store.dispatch("doReset", this.userData.email); 
                this.resetData();
            } catch (error) {
                if (error.code === "auth/user-not-found") {
                    this.errorObj.noexiste = "El usuario no existe o ha sido eliminado.";
                }
            }
        }
    }
    },
    components: {
        VueBasicAlert
    }
  }
</script>

<style scoped>

.formPass{
margin-top: -5px;
color: #000000;
font-weight: 500;
font-size: 16px;
margin-bottom: 18px;
margin-left: 4px;
}

.imagenweb{
max-height:500px;

}
.error-mess2{
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
}

.login-divider {
display: flex;
align-items: center;
text-align: center;
width: 90%;
}

.line {
flex-grow: 1;
height: 1px; /* Ajusta el grosor de la línea */
background-color: #000; /* Ajusta el color de la línea */
}

.iconEye{
color: black;
font-size: 22px;
margin-right: 5px;
}

.or-circle {

line-height: 40px; /* Asegura que la 'O' esté centrada verticalmente */
background-color: #fff; /* Ajusta el color de fondo del círculo */
color: #000; /* Ajusta el color de la 'O' */
margin: 0 10px; /* Ajusta la separación entre la línea y el círculo */
display: inline-block;
}

.notienes{
font-weight: 500;
font-size: 16px;
}
.btn{
display: flex;
align-items: center;
background: none;
background-color: var(--success-green);
border-radius: 12px;
width: 100%;
padding: 10px;
color: white;
border: none;
margin-bottom: 18px;
box-shadow: 0 0 4px 0 #828a8b;
justify-content: center;
font-size: 22px;

}
.error-mess {
  font-size: 16px;
    position: relative;
    color: #FF6961;
    margin: 0;
    text-align: end;
    padding: 0;
    margin-right: 8px;
    width: 100%;
}
.imagen{
display: flex;
justify-content: center;
align-items: center;
}

.form-control2{
border: none;
padding-left: 12px;
width: 100%;
}

.container {
  display: flex; /* Usa flexbox para el contenedor */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 90vh; /* Establece la altura del contenedor al 100% de la altura de la ventana del navegador */
}

.imagen-centrada {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}

/* .container {
background-image: url("../assets/images/fondowebmovil.svg");
background-size: cover;
background-position: center;
background-repeat: no-repeat;
display: flex;
justify-content: center;
align-items: center;
height: 700px;
width: 100%;
} */

.img {
max-width: 100%;
max-height: 100%;
}
.iniciarsesion{
font-size: 34px;
padding: 10px;
text-align: center;
margin-bottom: 20px;
width: 100%;
}
.googleinitdiv{
display: flex;
align-items: center;
padding: 10px;
background: #0097B2;
width: 100%;
border-radius: 12px;
justify-content: center;

}

.googleinit{
display: flex;
border: none;
align-items: center;
background: none;
color: white;
font-size: 20px;
border-radius: 12px;
width: auto;
padding: 8px;
border: none;
margin-bottom: 18px;
box-shadow: 0 0 4px 0 #828a8b;
justify-content: center;

}
.iconGoogle{
width: 25px;
height: 25px;
}

.google{
font-size: 20px;
margin: 0px;
padding-left: 8px;
font-weight: 500;
color: black;

}
.iconApple{
color: black;
font-size: 30px;
}

.prueba{
background-color: white;
border-radius: 12px;
padding: 1.5rem;
width: 95%;
margin-left: auto;
margin-right: auto;
box-shadow: 0 2px 4px 0 rgba(0,0,0,.16);
}
.has-text {
border: 1px solid red; /* Puedes personalizar los estilos del borde aquí */
}


.register-container {
padding: 2rem 9%;
}

.register-container .register-form-container {
background: #fff;
height: 90vh;

}

.container form {
position: static;
background-color: white;
border-radius: 0.5rem;
animation: fadeUp .4s linear;
height: auto;
min-width: 400px;
margin-top: 6px;

}



.form-control {
margin: 0.7rem 0;
border-radius: 0.5rem;
background: #f7f7f7;
padding: 2rem 1.2rem;
font-size: 18px;
color: #130f40;
text-transform: none;
width: 100%;
border: 1px solid #70bbb1;
}

.form-group {
display: flex;
align-items: center;
background: none;
color: white;
font-size: 20px;
border-radius: 12px;
width: auto;
padding: 8px;
border: none;
margin-bottom: 18px;
box-shadow: 0 0 4px 0 #828a8b;
}

form .error-mess {
font-size: 16px;
position: relative;
color: #FF6961;
margin: 0;
text-align: end;
padding: 0;
margin-right: 8px;
width: 100%;
}

@media (max-width: 768px) {

.container {
  display: flex; /* Usa flexbox para el contenedor */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 90vh; /* Establece la altura del contenedor al 100% de la altura de la ventana del navegador */
}

.imagen-centrada {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}

.container form {
position: static;
min-width: 400px;
margin-top: 0px;
padding: 1rem;
height: auto;
margin-right: auto;
margin-left: auto;
margin-top: 6px;
}
}

@media (max-width: 576px) {
.container {
  display: flex; /* Usa flexbox para el contenedor */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 90vh; /* Establece la altura del contenedor al 100% de la altura de la ventana del navegador */
}

.imagen-centrada {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}


.container form {
position: static;
height: auto;
min-width: 380px;
margin-top: 6px;
}


.form-control {
padding: 1.5rem 1rem;
font-size: 18px;
border: 1px solid #70bbb1;
}

.container form label {
font-size: 1.5rem;
}

.container form span {
font-size: 16px;
}

.container form p {
font-size: 18px;
text-align: center;
color: #FF6961;
margin-bottom: 12px;
}

.form-group .error-mess {
font-size: 16px;
position: relative;
color: #FF6961;
margin: 0;
text-align: end;
padding: 0;
margin-right: 8px;
width: 100%;
}


.form-group i {
font-size: 22px;
color: black;
}
}
@media (min-width: 992px) {
.container {
  display: flex; /* Usa flexbox para el contenedor */
  justify-content: center; /* Centra horizontalmente */
  align-items: center; /* Centra verticalmente */
  height: 90vh; /* Establece la altura del contenedor al 100% de la altura de la ventana del navegador */
}

.imagen-centrada {
  max-width: 100%; /* Asegura que la imagen no exceda el ancho del contenedor */
  max-height: 100%; /* Asegura que la imagen no exceda la altura del contenedor */
}


}
</style>
