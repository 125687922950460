import axios from "axios";
window.axios = axios
axios.defaults.withCredentials = false
// let backendUrl = "http://" + window.location.hostname.toString() + ":8081/api"

let backendUrl = process.env.VUE_APP_APIURL
axios.defaults.baseURL = backendUrl

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    if (error.response.status === 401 && !refresh) {
        refresh = true;

        const {status, user} = await axios.post('refresh', {}, {
            withCredentials: true
        });

        if (status === 200) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${user.accessToken}`;

            return axios(error.config);
        }
    }
    refresh = false;
    return error;
});