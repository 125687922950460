<template>
  <div class="header">
    
    <router-link to="/negocios" class="logo">
      <img class="img" src="../assets/images/mizatec.png" alt="Logo" />
    </router-link>

    <div v-if="!user && !isLoginRoute" class="accountnouser" @click="showLog">
      <router-link class="name" to="/login">Iniciar sesión</router-link>
    </div>

    <div v-if="user" class="account"  @click="isDropdownOpen = !isDropdownOpen">
      <i class="fa-regular fa-circle-user additional-class"></i>
      <p class="name">{{ user.displayName }}</p>
      <ul class="account drop-down-select" v-if="isDropdownOpen" >
        <li class="lista" @click="doLogOut">
          <p class="p">Salir</p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
/* eslint-disable */ 
export default {
  name: 'NavBar',
  
  data() {
  return {
      isDropdownOpen: true,
      currentRoute: null
  };
},

watch: {
$route(to, from) {
  this.currentRoute = to.name;
}
},

  computed: {
      ...mapState(["user"]),

      isLoginRoute() {
        return this.$route.path === '/login' || this.$route.path === '/';
  },
      firstName() {
    return this.getFirstName(this.user.displayName);
  },

  },
  mounted() {
      window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
      window.removeEventListener('scroll', this.handleScroll);
  },
  
  methods: {

       async doLogOut() {
          try {
          await this.$store.dispatch("doLogout");
          this.$router.push("/");
          console.log("Logged out");
          } catch (error) {
          console.error(error.message);
          }
      },

      getFirstName(fullName) {
        const parts = fullName.split(' ');
        if (parts.length > 0) {
          const firstName = parts[0];
          const secondName = parts[1];
          
          const capitalizedFirstName = firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
          const capitalizedSecondName = secondName ? secondName.charAt(0).toUpperCase() + secondName.slice(1).toLowerCase() : '';

          return capitalizedFirstName + (capitalizedSecondName ? ' ' + capitalizedSecondName : '');
        } else {
          return ''; // Devuelve una cadena vacía si el nombre completo no se puede dividir correctamente
        }
      },

      closeDropdown() {
        this.isDropdownOpen = false;
      },

      redirectPerfil() {
        this.$router.push({ name: "perfil" });
        this.closeDropdown();
      },

      showNav() {
        let navbar = document.querySelector('.header .navbar');
        navbar.classList.toggle('active');
      },

      showLog() {
        let mq = window.matchMedia("(max-width: 768px)");
        if (mq.matches) {
          this.isDropdownOpen = !this.isDropdownOpen; // Cambia el estado del menú desplegable
        }
      }
      
      
  }
}
</script>

<style scoped>

.active {
background-color: #f7f7f7;
/* Otros estilos de resaltado */
}
.header {
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem 9%;
}

.additional-class {
  font-size: 26px; color: rgb(86, 87, 97);
}

.name{
  color: rgb(86, 87, 97);
  text-align: center;
  font-size: 18px;
  margin-bottom: 0;
  margin-left: 6px;
  text-transform: capitalize;
}

.header .logo img {
  width: 200px;
}


.header  div:hover {
  color: black;
 border-radius: 12px;
  background: #dbdbdb !important;
}
#menu-btn {
  display: none;
}
.img {
     width: 70%;
    padding: 10px;
}
.account {
    position: relative;
    display: flex;
    padding: 6px;
    align-items: center;
    cursor: pointer;
}

.account .drop-down-select {
  position: absolute;
  top: 100%; /* Posiciona el menú justo debajo del contenedor .account */
  left: 0; /* Alinea el menú con el lado izquierdo del contenedor .account */
  display: none; /* Esconde el menú por defecto */
  width: auto; /* Ajusta el ancho según sea necesario */
  padding: 10px;
  list-style-type: none;
  border-radius: 12px;
  background-color: white;
  z-index: 1; /* Asegura que el menú se muestre por encima de otros elementos */
}

.account:hover .drop-down-select {
  display: block; /* Muestra el menú cuando .account se encuentra en estado hover */
}


.lista {
  padding: 11px;
}
.lista:hover {
  color: black;
}

.lista p {
  color:rgb(86, 87, 97);
  font-size: 18px;
}

@media (max-width: 768px) {
.header {
  display: flex;
    padding-right: 15px;
    padding-left: 15px;
    padding-top: 15px;
    padding-bottom: 5px;
    justify-content: space-between;
}

.header .navbar {
  display: none;
}

.header .logo img {
  width: 200px;
}


.additional-class {
  font-size: 25px;
  width: auto;
      color: rgb(86, 87, 97);}
.name{
  color: rgb(86, 87, 97);
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  width: auto;
  /* margin-bottom: 0; */
  /* margin-left: 6px; */
  text-transform: capitalize;
}

.lista {
  padding: 11px;
}
.lista p {
  color:rgb(86, 87, 97);
  font-size: 18px;
}
}

@media (max-width: 576px) {
  .header .logo img {
  width: 200px;
}
  .header a {
      float: right;
  width: auto;    }

  .header a:hover {
      border-radius: 12px;
}
.lista {
  padding: 11px;
}
.lista p {
  color:rgb(86, 87, 97);
  font-size: 18px;
}

.name{
  color: rgb(86, 87, 97);
  text-align: center;
  font-size: 20px;
  width: auto;

  text-transform: capitalize;
}
.name:hover{
      border-radius: 12px;
}
}
@media (min-width: 1024px) {
  .header .logo img {
  width: 250px;
}
.name{
  font-size: 20px;
}

.caja1{
width: 60%;
margin-bottom: 30px;
}

.caja2{
width: 60%;
background: #fff;
border-radius: 12px;
padding: 16px;
height: auto;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
margin: 0;
margin-bottom: 30px;
}
.caja3{
width: 60%;
background: #fff;
border-radius: 12px;
padding: 16px;
height: auto;
box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .16);
margin: 0;
margin-bottom: 30px;

}

}
</style>
